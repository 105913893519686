import { ACTION_TYPES } from "../actions/contact";

const initialState = {
    contacts: [],
    metaContact: {}
}

export const contact = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.CONTACT_FETCH_ALL:
            return {
                ...state,
                contacts: [...action.payload]
            }
        case ACTION_TYPES.CONTACT_CREATE:
            return {
                ...state,
                contacts: [...state.contacts, action.payload]
            }
        case ACTION_TYPES.CONTACT_UPDATE:
            return {
                ...state,
                contacts: state.contacts.map(x => x.id === action.payload.id ? action.payload : x)
            }
        case ACTION_TYPES.CONTACT_DELETE:
            return {
                ...state,
                contacts:state.contacts.filter(x => x.id !== action.payload)
            }
        case ACTION_TYPES.CONTACT_PAGINATION:
  
            return {
                ...state,
                contacts: [...action.payload.contacts],
                metaContact: action.payload.meta
            }
        default:
            return state;
    }
}