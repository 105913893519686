import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { toast } from 'react-toastify';
import Grow from '@material-ui/core/Grow';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const initialFormState = {
  id: null,
  transaction_no: "",
  customer_name: "",
  customer_email: "",
  customer_mobile: "",
  customer_id: "",
  transaction_amount: 0,
  subTotal: 0,
  discount: 0,
  promocode: "",
  orderedServices: {},
  transaction_status: "",
  order_status: "",
  note: "",

}

const FormDialogAddOrder = (props) => {
  const [open, setOpen] = useState(false);
  const [order, setOrder] = useState(initialFormState);
  const [errors, setErrors] = useState({})

  const handleClickOpen = () => {
    setErrors({});
    setOrder(initialFormState);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleInputChange = event => {
    const { name, value } = event.target
    setOrder({ ...order, [name]: value })
  }

  const validate = () => {
    let tempErrors = {};
    let formIsValid = true;

    if (!order.transaction_no || order.transaction_no.trim() === "") {
      formIsValid = false;
      tempErrors["transaction_no"] = "Cannot be empty";
    }


    setErrors(tempErrors);
    return formIsValid;
  }

  const handleSubmit = (e) => {
    const onSuccess = () => {
      props.refresh()
      setOpen(false);
      toast.success('Data succesfully updated');
    }
    e.preventDefault();

    if (validate()) {
      props.create(order, onSuccess)
    }
  }

  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen} >
        <AddCircleIcon style={{ fontSize: "40px" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle id="form-dialog-title" style={{ padding: "30px 30px 0px 30px" }}>Add order</DialogTitle>

        <DialogContent style={{ padding: "30px 30px 10px 30px" }}>

          <TextField
            autoFocus
            name="transaction_no"
            label="Transaction No"
            value={order.transaction_no}
            fullWidth
            onChange={handleInputChange}
            {...(errors.transaction_no && { error: true, helperText: errors.transaction_no })}
          />

          <br /><br />
          <TextField

            name="customer_name"
            label="Customer Name"
            value={order.customer_name}
            fullWidth
            onChange={handleInputChange}
            {...(errors.customer_name && { error: true, helperText: errors.customer_name })}
          />
          <br /><br />
          <TextField

            name="customer_email"
            label="Customer Email"
            value={order.customer_email}
            fullWidth
            onChange={handleInputChange}
            {...(errors.customer_email && { error: true, helperText: errors.customer_email })}
          />
          <br /><br />
          <TextField

            name="customer_mobile"
            label="Customer Mobile"
            value={order.customer_mobile}
            fullWidth
            onChange={handleInputChange}
            {...(errors.customer_mobile && { error: true, helperText: errors.customer_mobile })}
          />
          <br /><br />
          <TextField

            name="transaction_amount"
            label="Transaction Amount"
            value={order.transaction_amount}
            fullWidth
            onChange={handleInputChange}
            {...(errors.transaction_amount && { error: true, helperText: errors.transaction_amount })}
          />
          <br /><br />
          <TextField

            name="subTotal"
            label="Sub Total"
            value={order.subTotal}
            fullWidth
            onChange={handleInputChange}
            {...(errors.subTotal && { error: true, helperText: errors.subTotal })}
          />
          <br /><br />
          <TextField

            name="discount"
            label="Discount"
            value={order.discount}
            fullWidth
            onChange={handleInputChange}
            {...(errors.discount && { error: true, helperText: errors.discount })}
          />
          <br /><br />
          <TextField

            name="promocode"
            label="Promocode"
            value={order.promocode}
            fullWidth
            onChange={handleInputChange}
            {...(errors.promocode && { error: true, helperText: errors.promocode })}
          />
          <br /><br />
          <TextField

            name="transaction_status"
            label="Transaction Status"
            value={order.transaction_status}
            fullWidth
            onChange={handleInputChange}
            {...(errors.transaction_status && { error: true, helperText: errors.transaction_status })}
          />


          <br /><br />
          <TextField
            
            name="order_status"
            label="Order Status"
            value={order.order_status}
            fullWidth
            onChange={handleInputChange}
            multiline={true}
            rows={2}
            {...(errors.order_status && { error: true, helperText: errors.order_status })}
          />

          <TextField

            name="note"
            label="Note"
            value={order.note}
            fullWidth
            onChange={handleInputChange}
            {...(errors.note && { error: true, helperText: errors.note })}
          />

        </DialogContent>

        <DialogActions style={{ padding: 30 }}>
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="secondary">
            Save
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}

export default FormDialogAddOrder;