import API from "../utils/api";

export const ACTION_TYPES = {
    DOCTORBOOKING_CREATE: 'DOCTORBOOKING_CREATE',
    DOCTORBOOKING_UPDATE: 'DOCTORBOOKING_UPDATE',
    DOCTORBOOKING_DELETE: 'DOCTORBOOKING_DELETE',
    DOCTORBOOKING_FETCH: 'DOCTORBOOKING_FETCH',
    DOCTORBOOKING_FETCH_ALL: 'DOCTORBOOKING_FETCH_ALL',
    DOCTORBOOKING_PAGINATION: 'DOCTORBOOKING_PAGINATION',
    DOCTORBOOKING_FEATURED: 'DOCTORBOOKING_FEATURED'
}

export const fetchAll = () => dispatch => {
    API.doctorbooking().fetchAll()
        .then(res => {
      
            dispatch({
                type: ACTION_TYPES.DOCTORBOOKING_FETCH_ALL,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const Pagination = (page = 1, limit = 5, status = "") => dispatch => {
    API.doctorbooking().fetchPagination(page, Math.abs(limit), status)
        .then(res =>{
     
            dispatch({
                type: ACTION_TYPES.DOCTORBOOKING_PAGINATION,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const fetchById = (id, onSuccess) => dispatch => {
    API.doctorbooking().fetchById(id)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.DOCTORBOOKING_FETCH,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}

export const create = (input, onSuccess) => dispatch => {
    API.doctorbooking().create(input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.DOCTORBOOKING_CREATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const update = (id, input, onSuccess) => dispatch => {
    
    API.doctorbooking().update(id, input)
        .then(res =>{
            
            dispatch({
                type: ACTION_TYPES.DOCTORBOOKING_UPDATE,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}

export const Delete = (id, onSuccess) => dispatch => {
    API.doctorbooking().delete(id)
        .then(res =>{
         
            dispatch({
                type: ACTION_TYPES.DOCTORBOOKING_DELETE,
                payload: res.data.id
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const getFeatured = (param) => dispatch => {
    
    API.doctorbooking().getFeatured(param)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.DOCTORBOOKING_FEATURED,
                payload: res.data
            })
          //  onSuccess(res.data)
        })
        .catch(err => console.log(err))
}