import { ACTION_TYPES } from "../actions/doctorbooking";

const initialState = {
    doctorbookings: [],
    metaDoctorbooking: {},
    featured: []
}

export const doctorbooking = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.DOCTORBOOKING_FETCH_ALL:
            return {
                ...state,
                doctorbookings: [...action.payload]
            }
            case ACTION_TYPES.DOCTORBOOKING_FEATURED:
                console.log(action.payload)
                return {
                    ...state,
                    featured: [...action.payload]
                }
        case ACTION_TYPES.DOCTORBOOKING_CREATE:
            return {
                ...state,
                doctorbookings: [...state.doctorbookings, action.payload]
            }
        case ACTION_TYPES.DOCTORBOOKING_UPDATE:
            return {
                ...state,
                doctorbookings: state.doctorbookings.map(x => x.id === action.payload.id ? action.payload : x)
            }
        case ACTION_TYPES.DOCTORBOOKING_DELETE:
            return {
                ...state,
                doctorbookings:state.doctorbookings.filter(x => x.id !== action.payload)
            }
        case ACTION_TYPES.DOCTORBOOKING_PAGINATION:
            
            return {
                ...state,
                doctorbookings: [...action.payload.doctorbookings],
                metaDoctorbooking: action.payload.meta
            }
        default:
            return state;
    }
}