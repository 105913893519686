import { ACTION_TYPES } from "../actions/unit";

const initialState = {
    units: [],
    metaUnit: {}
}

export const unit = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.UNIT_FETCH_ALL:
            return {
                ...state,
                units: [...action.payload]
            }
        case ACTION_TYPES.UNIT_CREATE:
            return {
                ...state,
                units: [...state.units, action.payload]
            }
        case ACTION_TYPES.UNIT_UPDATE:
            return {
                ...state,
                units: state.units.map(x => x.id === action.payload.id ? action.payload : x)
            }
        case ACTION_TYPES.UNIT_DELETE:
            return {
                ...state,
                units:state.units.filter(x => x.id !== action.payload)
            }
        case ACTION_TYPES.UNIT_PAGINATION:
    
            return {
                ...state,
                units: [...action.payload.units],
                metaUnit: action.payload.meta
            }
        default:
            return state;
    }
}