import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from '@material-ui/core';
import RichTextEditor from 'react-rte';
import EditIcon from '@material-ui/icons/Edit';
import { toast } from 'react-toastify';
import Grow from '@material-ui/core/Grow';
import API from "../../../utils/api";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const initialFormState = {
  id: null,
  image: "",
  name: "",
  categoryId: [],
  about: "",
  departments: [],
}

const FormDialogEditBooking = (props) => {
  const [open, setOpen] = useState(false);
  const [booking, setBooking] = useState(initialFormState);
  const [errors, setErrors] = useState({})
  
  const [selectedLang, setSelectedLang] = useState('en')
  const [htmlDesc, setHtmlDesc] = useState(RichTextEditor.createValueFromString(props.dataBooking[4][selectedLang], 'html'))
  const handleClickOpen = () => {

    setErrors({});
    setBooking({
      id: props.dataBooking[0],
      image: props.dataBooking[1],
      name: props.dataBooking[2],
      categoryId: props.dataBooking[3],
      about: props.dataBooking[4],
      departments: props.dataBooking[5]?props.dataBooking[5]:[],
      branch: props.dataBooking[6],
    })
 
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleInputChange = event => {
    const { name, value, lang } = event.target

    if (lang) {
      setBooking({
        ...booking,
        [name]: { ...booking[name], [selectedLang]: value }
      });

    } else {
      setBooking({ ...booking, [name]: value })
    }
  }

  useEffect(() => {
    
    if(booking.about.ar){
    if(selectedLang==='ar'){
      setHtmlDesc(RichTextEditor.createValueFromString(booking.about.ar, 'html'))
    }else{
      setHtmlDesc(RichTextEditor.createValueFromString(booking.about.en, 'html'))
    }
  }
  }, [selectedLang]);

  const handleHtmlInputChange = value => {

    setHtmlDesc(value)
  //  setBooking({ ...booking, about: value.toString('html') })
    setBooking({
      ...booking,
      ['about']: { ...booking['about'], [selectedLang]: value.toString('html') }
    });
  }

  const handleInputChangeForFileType = event => {
 
    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());
    API.utility().upload(formdata)
    .then(res =>{
      setBooking({ ...booking, image: res.data.data.path })
    })

  }

  const validate = () => {
    let tempErrors = {};
    let formIsValid = true;

    if (!booking.image || booking.image.trim() === "") {
      formIsValid = false;
      tempErrors["image"] = "Cannot be empty";
    }

    if (!booking.categoryId) {
      formIsValid = false;
      tempErrors["categoryId"] = "Cannot be empty";
    }


    setErrors(tempErrors);
    return formIsValid;
  }

  const handleChange = (event) => {
    setBooking({ ...booking, categoryId:  event.target.value })
    
  };
  const handleBranchChangeForServices = (event) => {
    setBooking({ ...booking, branch: event.target.value })

  };
  const handleSubmit = (e) => {

    const onSuccess = () => {
      setOpen(false);
      toast.success('Data succesfully updated');
    }
    e.preventDefault();

    if (validate()) {

      props.update(booking.id, booking, onSuccess)
    }
  }

  const handleChangeForDepartments = (event) => {
    setBooking({ ...booking, departments: event.target.value })

  };

  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title" style={{ padding: "30px 30px 0px 30px" }}>Edit booking</DialogTitle>

        <DialogContent style={{ padding: "30px 30px 10px 30px" }}>


<div className="row">
  <div className="col-lg-12 col-12 mb-4">
    <Button variant="contained" component="label">
      Upload Picture
      <input
        type="file"
        name="file"
        label="Image"
        onChange={handleInputChangeForFileType}
        {...(errors.file && { error: true, helperText: errors.file })}
        hidden
      />
    </Button>
    <img src={process.env.REACT_APP_IMG_URL + booking.image} height="100" style={{ marginTop: "-30px", float: "right" }} />
  </div>
</div>

<div className="row border p-2">

  <div className="col-lg-12 col-12 mb-4">
    <FormControl className="float-end">
      <InputLabel id="demo-simple-select-label">Language</InputLabel>
      <Select
        value={selectedLang}
        onChange={(e) => setSelectedLang(e.target.value)}
      >
        <MenuItem value="en">English</MenuItem>
        <MenuItem value="ar">Arabic</MenuItem>
      </Select>
    </FormControl>
  </div>

  <div className="col-lg-12 col-12 mb-4">
    <TextField

      name="name"
      label="Name"
      value={booking?.name[selectedLang]}
      inputProps={{ 'lang': selectedLang }}
      fullWidth
      onChange={handleInputChange}
      {...(errors.name && { error: true, helperText: errors.name })}
    />
  </div>

  <div className="col-lg-12 col-12 mb-4">
    <p>About</p>
    <RichTextEditor
      value={htmlDesc}
      onChange={handleHtmlInputChange}
    />
  </div>

</div>

<FormControl
  fullWidth={true}
>
  <InputLabel id="demo-simple-select-label">Category</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={booking.categoryId}
    onChange={handleChange}
  
  >
    {props.categories.map(item => (
      <MenuItem value={item.id}>{item.title?.en}</MenuItem>
    ))}

  </Select>
</FormControl>


<br /><br />

<FormControl
  fullWidth={true}
>
  <InputLabel id="demo-simple-select-label">Departments</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={booking.departments}
    onChange={handleChangeForDepartments}
    multiple
  >
    {props.departments.map(item => (
      <MenuItem value={item.id}>{item.title.en}</MenuItem>
    ))}

  </Select>
</FormControl>


<br /><br />



<FormControl
  fullWidth={true}
>
  <InputLabel id="demo-simple-select-label">Choose Centre/Branch</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={booking.branch}
    onChange={handleBranchChangeForServices}

  >
    {props.branches.map(item => (
      <MenuItem value={item.id}>{item.name}</MenuItem>
    ))}

  </Select>
</FormControl>

<br /><br />


</DialogContent>

        <DialogActions style={{ padding: 30 }}>
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="secondary">
            Save
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}

export default FormDialogEditBooking;