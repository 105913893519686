import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton, Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { toast } from 'react-toastify';
import Grow from '@material-ui/core/Grow';
import API from "../../../utils/api";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const initialFormState = {
  id: null,
  image: "https://picsum.photos/200",
  name: "",
  about: "",
  location: ""
}

const FormDialogEditBranche = (props) => {
  const [open, setOpen] = useState(false);
  const [branche, setBranche] = useState(initialFormState);
  const [errors, setErrors] = useState({})


  const handleClickOpen = () => {

    setErrors({});
    setBranche({
      id: props.dataBranche[0],
      image: props.dataBranche[1],
      name: props.dataBranche[2],
      about: props.dataBranche[3],
      location: props.dataBranche[4],
      latitude: props.dataBranche[5],
      longitude: props.dataBranche[6],
      website: props.dataBranche[7],
      email: props.dataBranche[8],
      phone: props.dataBranche[9],
    })
   
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleInputChange = event => {
    const { name, value } = event.target
    setBranche({ ...branche, [name]: value })
  }
  const handleInputChangeForFileType = event => {
 
    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());
    API.utility().upload(formdata)
    .then(res =>{
      setBranche({ ...branche, image: res.data.data.path })
    })

  }

  const validate = () => {
    let tempErrors = {};
    let formIsValid = true;

    if (!branche.image || branche.image.trim() === "") {
      formIsValid = false;
      tempErrors["image"] = "Cannot be empty";
    }
    if (!branche.name || branche.name.trim() === "") {
      formIsValid = false;
      tempErrors["name"] = "Cannot be empty";
    }

    setErrors(tempErrors);
    return formIsValid;
  }

  const handleChange = (event) => {
    setBranche({ ...branche, categoryId:  event.target.value })
    
  };

  const handleSubmit = (e) => {

    const onSuccess = () => {
      setOpen(false);
      toast.success('Data succesfully updated');
    }
    e.preventDefault();

    if (validate()) {

      props.update(branche.id, branche, onSuccess)
    }
  }

  const handleChangeForDepartments = (event) => {
    setBranche({ ...branche, departments: event.target.value })

  };

  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle id="form-dialog-title" style={{ padding: "30px 30px 0px 30px" }}>Edit branch</DialogTitle>

        <DialogContent style={{ padding: "30px 30px 10px 30px" }}>

        <Button variant="contained" component="label">
        Upload Picture
            <input
              type="file"
              name="file"
              label="Image"
              onChange={handleInputChangeForFileType}
              {...(errors.file && { error: true, helperText: errors.file })}
              hidden
            />
          </Button>
  
  <img src={process.env.REACT_APP_IMG_URL+branche.image} height="100" style={{ marginTop: "-30px", float: "right" }} />

  <TextField
    
    name="name"
    label="Name"
    value={branche.name}
    fullWidth
    onChange={handleInputChange}
    {...(errors.name && { error: true, helperText: errors.name })}
  />
  <br /><br />


  <TextField
    
    name="location"
    label="Location"
    value={branche.location}
    fullWidth
    onChange={handleInputChange}
    multiline={true}
    rows={2}
    {...(errors.location && { error: true, helperText: errors.location })}
  />
  <br /><br />


  <TextField
            
            name="latitude"
            label="Latitude"
            value={branche.latitude}
            fullWidth
            onChange={handleInputChange}
            multiline={true}
            rows={2}
            {...(errors.latitude && { error: true, helperText: errors.latitude })}
          />
          <br /><br />
          <TextField
            
            name="longitude"
            label="Longitude"
            value={branche.longitude}
            fullWidth
            onChange={handleInputChange}
            multiline={true}
            rows={2}
            {...(errors.longitude && { error: true, helperText: errors.longitude })}
          />
          <br /><br />
          <TextField
            
            name="website"
            label="Website"
            value={branche.website}
            fullWidth
            onChange={handleInputChange}
            multiline={true}
            rows={2}
            {...(errors.website && { error: true, helperText: errors.website })}
          />
          <br /><br />
          <TextField
            
            name="email"
            label="Support Email"
            value={branche.email}
            fullWidth
            onChange={handleInputChange}
            multiline={true}
            rows={2}
            {...(errors.email && { error: true, helperText: errors.email })}
          />
          <br /><br />
          <TextField
            
            name="phone"
            label="Phone"
            value={branche.phone}
            fullWidth
            onChange={handleInputChange}
            multiline={true}
            rows={2}
            {...(errors.phone && { error: true, helperText: errors.phone })}
          />
          <br /><br />


  <TextField
    
    name="about"
    label="about"
    value={branche.about}
    fullWidth
    onChange={handleInputChange}
    multiline={true}
    rows={2}
    {...(errors.about && { error: true, helperText: errors.about })}
  />


</DialogContent>

        <DialogActions style={{ padding: 30 }}>
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="secondary">
            Save
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}

export default FormDialogEditBranche;