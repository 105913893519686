import API from "../utils/api";

export const ACTION_TYPES = {
    COUPON_CREATE: 'COUPON_CREATE',
    COUPON_UPDATE: 'COUPON_UPDATE',
    COUPON_DELETE: 'COUPON_DELETE',
    COUPON_FETCH: 'COUPON_FETCH',
    COUPON_FETCH_ALL: 'COUPON_FETCH_ALL',
    COUPON_PAGINATION: 'COUPON_PAGINATION'
}

export const fetchAll = () => dispatch => {
    API.coupon().fetchAll()
        .then(res => {
      
            dispatch({
                type: ACTION_TYPES.COUPON_FETCH_ALL,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const Pagination = (page = 1, limit = 5, title = "") => dispatch => {
    API.coupon().fetchPagination(page, Math.abs(limit), title)
        .then(res =>{
        
            dispatch({
                type: ACTION_TYPES.COUPON_PAGINATION,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const fetchById = (id, onSuccess) => dispatch => {
    API.coupon().fetchById(id)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.COUPON_FETCH,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}

export const create = (input, onSuccess) => dispatch => {
    API.coupon().create(input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.COUPON_CREATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const update = (id, input, onSuccess) => dispatch => {
   
    API.coupon().update(id, input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.COUPON_UPDATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const Delete = (id, onSuccess) => dispatch => {
    API.coupon().delete(id)
        .then(res =>{
         
            dispatch({
                type: ACTION_TYPES.COUPON_DELETE,
                payload: res.data.id
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}