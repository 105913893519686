import API from "../utils/api";

export const ACTION_TYPES = {
    UNIT_CREATE: 'UNIT_CREATE',
    UNIT_UPDATE: 'UNIT_UPDATE',
    UNIT_DELETE: 'UNIT_DELETE',
    UNIT_FETCH: 'UNIT_FETCH',
    UNIT_FETCH_ALL: 'UNIT_FETCH_ALL',
    UNIT_PAGINATION: 'UNIT_PAGINATION'
}

export const fetchAll = () => dispatch => {
    API.unit().fetchAll()
        .then(res => {
      
            dispatch({
                type: ACTION_TYPES.UNIT_FETCH_ALL,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const Pagination = (page = 1, limit = 5, title = "") => dispatch => {
    API.unit().fetchPagination(page, Math.abs(limit), title)
        .then(res =>{
         
            dispatch({
                type: ACTION_TYPES.UNIT_PAGINATION,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const fetchById = (id, onSuccess) => dispatch => {
    API.unit().fetchById(id)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.UNIT_FETCH,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}

export const create = (input, onSuccess) => dispatch => {
    API.unit().create(input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.UNIT_CREATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const update = (id, input, onSuccess) => dispatch => {
   
    API.unit().update(id, input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.UNIT_UPDATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const Delete = (id, onSuccess) => dispatch => {
    API.unit().delete(id)
        .then(res =>{
         
            dispatch({
                type: ACTION_TYPES.UNIT_DELETE,
                payload: res.data.id
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}