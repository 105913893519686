import { ACTION_TYPES } from "../actions/giftcard";

const initialState = {
    giftcards: [],
    metaGiftcard: {}
}

export const giftcard = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.GIFTCARD_FETCH_ALL:
            return {
                ...state,
                giftcards: [...action.payload]
            }
        case ACTION_TYPES.GIFTCARD_CREATE:
            return {
                ...state,
                giftcards: [...state.giftcards, action.payload]
            }
        case ACTION_TYPES.GIFTCARD_UPDATE:
            return {
                ...state,
                giftcards: state.giftcards.map(x => x.id === action.payload.id ? action.payload : x)
            }
        case ACTION_TYPES.GIFTCARD_DELETE:
            return {
                ...state,
                giftcards:state.giftcards.filter(x => x.id !== action.payload)
            }
        case ACTION_TYPES.GIFTCARD_PAGINATION:

            return {
                ...state,
                giftcards: [...action.payload.giftcards],
                metaGiftcard: action.payload.meta
            }
        default:
            return state;
    }
}