import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { toast } from 'react-toastify';
import Grow from '@material-ui/core/Grow';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const initialFormState = {
  id: null,
  title: "",
  lastName: "",
  description: ""
}

const FormDialogEditReview = (props) => {
  const [open, setOpen] = useState(false);
  const [review, setReview] = useState(initialFormState);
  const [errors, setErrors] = useState({})

  const handleClickOpen = () => {
    setErrors({});
    setReview({
      id: props.dataReview[0],
      customerName: props.dataReview[1],
      rating: props.dataReview[2],
      description: props.dataReview[3],

    })
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleInputChange = review => {
    const { name, value } = review.target
    setReview({ ...review, [name]: value })
  }

  const validate = () => {
    let tempErrors = {};
    let formIsValid = true;

    if (!review.rating || review.rating.trim() === "") {
      formIsValid = false;
      tempErrors["rating"] = "Cannot be empty";
    }

    if (!review.description || review.description.trim() === "") {
      formIsValid = false;
      tempErrors["description"] = "Cannot be empty";
    }


    setErrors(tempErrors);
    return formIsValid;
  }

  const handleSubmit = (e) => {

    const onSuccess = () => {
      setOpen(false);
      toast.success('Data succesfully updated');
    }
    e.preventDefault();

    if (validate()) {
      props.update(review.id, review, onSuccess)
    }
  }

  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle id="form-dialog-title" style={{ padding: "30px 30px 0px 30px" }}>Edit review</DialogTitle>

        <DialogContent style={{ padding: "30px 30px 10px 30px" }}>


          <TextField

            name="customerName"
            label="Customer Name"
            value={review.customerName}
            fullWidth
            onChange={handleInputChange}
            {...(errors.customerName && { error: true, helperText: errors.customerName })}
          />
          <br /><br />
          <TextField
            autoFocus
            name="rating"
            label="Rating"
            value={review.rating}
            fullWidth
            onChange={handleInputChange}
            {...(errors.rating && { error: true, helperText: errors.rating })}
          />



          <br /><br />
          <TextField
            autoFocus
            name="description"
            label="Description"
            value={review.description}
            fullWidth
            onChange={handleInputChange}
            multiline={true}
            rows={2}
            {...(errors.description && { error: true, helperText: errors.description })}
          />



        </DialogContent>

        <DialogActions style={{ padding: 30 }}>
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="secondary">
            Save
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}

export default FormDialogEditReview;