import { ACTION_TYPES } from "../actions/blog";

const initialState = {
    blogs: [],
    metaBlog: {}
}

export const blog = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.BLOG_FETCH_ALL:
            return {
                ...state,
                blogs: [...action.payload]
            }
        case ACTION_TYPES.BLOG_CREATE:
            return {
                ...state,
                blogs: [...state.blogs, action.payload]
            }
        case ACTION_TYPES.BLOG_UPDATE:
            return {
                ...state,
                blogs: state.blogs.map(x => x.id === action.payload.id ? action.payload : x)
            }
        case ACTION_TYPES.BLOG_DELETE:
            return {
                ...state,
                blogs:state.blogs.filter(x => x.id !== action.payload)
            }
        case ACTION_TYPES.BLOG_PAGINATION:
      
            return {
                ...state,
                blogs: [...action.payload.blogs],
                metaBlog: action.payload.meta
            }
        default:
            return state;
    }
}