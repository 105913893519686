import API from "../utils/api";

export const ACTION_TYPES = {
    ORDER_CREATE: 'ORDER_CREATE',
    ORDER_UPDATE: 'ORDER_UPDATE',
    ORDER_DELETE: 'ORDER_DELETE',
    ORDER_FETCH: 'ORDER_FETCH',
    ORDER_FETCH_ALL: 'ORDER_FETCH_ALL',
    ORDER_PAGINATION: 'ORDER_PAGINATION',
    ORDER_FETCH_COUNT: 'ORDER_FETCH_COUNT'
}

export const getCount = () => dispatch => {
    API.order().getCount()
        .then(res => {
      
            dispatch({
                type: ACTION_TYPES.ORDER_FETCH_COUNT,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const Pagination = (page = 1, limit = 5, transaction_no,customer_name,FilterTodays) => dispatch => {
    API.order().fetchPagination(page, Math.abs(limit), transaction_no, customer_name, FilterTodays)
        .then(res =>{
         
            dispatch({
                type: ACTION_TYPES.ORDER_PAGINATION,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const fetchById = (id, onSuccess) => dispatch => {
    API.order().fetchById(id)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.ORDER_FETCH,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}

export const create = (input, onSuccess) => dispatch => {
    API.order().create(input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.ORDER_CREATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const update = (id, input, onSuccess) => dispatch => {
   
    API.order().update(id, input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.ORDER_UPDATE,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}

export const Delete = (id, onSuccess) => dispatch => {
    API.order().delete(id)
        .then(res =>{
         
            dispatch({
                type: ACTION_TYPES.ORDER_DELETE,
                payload: res.data.id
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}