import API from "../utils/api";

export const ACTION_TYPES = {
    DOCTOR_CREATE: 'DOCTOR_CREATE',
    DOCTOR_UPDATE: 'DOCTOR_UPDATE',
    DOCTOR_DELETE: 'DOCTOR_DELETE',
    DOCTOR_FETCH: 'DOCTOR_FETCH',
    DOCTOR_FETCH_ALL: 'DOCTOR_FETCH_ALL',
    DOCTOR_PAGINATION: 'DOCTOR_PAGINATION'
}

export const fetchAll = () => dispatch => {
    API.doctor().fetchAll()
        .then(res => {
    
            dispatch({
                type: ACTION_TYPES.DOCTOR_FETCH_ALL,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const Pagination = (page = 1, limit = 10, name = "", type= type) => dispatch => {
    API.doctor().fetchPagination(page, Math.abs(limit), name, type)
        .then(res =>{
           
            dispatch({
                type: ACTION_TYPES.DOCTOR_PAGINATION,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const fetchById = (id, onSuccess) => dispatch => {
    API.doctor().fetchById(id)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.DOCTOR_FETCH,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}

export const create = (input, onSuccess) => dispatch => {
    API.doctor().create(input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.DOCTOR_CREATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const update = (id, input, onSuccess) => dispatch => {

    API.doctor().update(id, input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.DOCTOR_UPDATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const Delete = (id, onSuccess) => dispatch => {
    API.doctor().delete(id)
        .then(res =>{
           
            dispatch({
                type: ACTION_TYPES.DOCTOR_DELETE,
                payload: res.data.id
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}