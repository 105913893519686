import { ACTION_TYPES } from "../actions/giftcardorder";

const initialState = {
    giftcardorders: [],
    metaGiftcardorder: {}
}

export const giftcardorder = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.GIFTCARDORDER_FETCH_ALL:
            return {
                ...state,
                giftcardorders: [...action.payload]
            }
        case ACTION_TYPES.GIFTCARDORDER_CREATE:
            return {
                ...state,
                giftcardorders: [...state.giftcardorders, action.payload]
            }
        case ACTION_TYPES.GIFTCARDORDER_UPDATE:
            return {
                ...state,
                giftcardorders: state.giftcardorders.map(x => x.id === action.payload.id ? action.payload : x)
            }
        case ACTION_TYPES.GIFTCARDORDER_DELETE:
            return {
                ...state,
                giftcardorders:state.giftcardorders.filter(x => x.id !== action.payload)
            }
        case ACTION_TYPES.GIFTCARDORDER_PAGINATION:

            return {
                ...state,
                giftcardorders: [...action.payload.giftcardorders],
                metaGiftcardorder: action.payload.meta
            }
        default:
            return state;
    }
}