import React, { useEffect, useState } from "react";
import { Paper, withStyles } from '@material-ui/core';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import API from "../../../utils/api";
import { toast } from 'react-toastify';
import Picker from 'emoji-picker-react';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import Grid from '@material-ui/core/Grid';
import { IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from '@material-ui/core';
import * as service from "../../../actions/service";
import * as coupon from "../../../actions/coupon";
import * as offer from "../../../actions/offer";
import * as user from "../../../actions/user";

const styles = theme => ({
    paperTable: {
        padding: theme.spacing(0),
    }
})
const initialFormState = {

    title: "",
    description: "",
    image: "",
    service: "",
    offer: "",
    coupon: "",
    users:[],
    sendToAll: true
}

const NotificationCenter = ({ classes, ...props }) => {
    const [notificationParam, setNotificationParam] = useState(initialFormState);
    const [errors, setErrors] = useState({})

    const [showTitleEmoji, setShowTitleEmoji] = useState(false)
    const [showBodyEmoji, setShowBodyEmoji] = useState(false)

    useEffect(() => {
        props.fetchServices();
        props.fetchOffers();
        props.fetchCoupon();
        props.fetchUser();
    }, [])

    const validate = () => {
        let tempErrors = {};
        let formIsValid = true;

        if (!notificationParam.title || notificationParam.title.trim() === "") {
            formIsValid = false;
            tempErrors["title"] = "Cannot be empty";
        }

        if (!notificationParam.description || notificationParam.description.trim() === "") {
            formIsValid = false;
            tempErrors["description"] = "Cannot be empty";
        }

        setErrors(tempErrors);
        return formIsValid;
    }

    const handleInputChange = event => {
        const { name, value } = event.target
        setNotificationParam({ ...notificationParam, [name]: value })
    }
    const handleInputChangeForFileType = event => {

        var formdata = new FormData();
        formdata.append("file", event.target.files[0]);
        formdata.append("documentId", Date.now());
        API.utility().upload(formdata)
            .then(res => {
                setNotificationParam({ ...notificationParam, image:  res.data.data.path })
            })

    }

    const handleSubmit = event => {
        if (validate()) {
            //el28mRPXdU-9qMf_C_MFx5:APA91bHB1og-1BGONcv17Vl3obnnfOAr_8D-_ycwC4JH6cv9UWmmTH3Co5HWnWQodVIx1UhLsbuI9VWSMEq51YqMsYbDREWzPgs6Xt8Y71xqwgCjNHy80Ur6AqbdE7HIWSRc3EvExK14
            const pushNotification = {
                "registration_ids": ['cv-ubahJcEBGktgcg3OaDP:APA91bEkJSU0mAO_rJf-AhNNzhDvx_1_gtgvGDTKhStNvUPmzXh9kvrj6nN1-26mzqveRIum8kRUHNFhEYfdTsvFVB9dCB7SGWhTYWgIkjWnkgPzGUSO_XtGk1xV4AeL6Ckt2N_fpjLA', 'dOb2eiWrTXKnWcS-FbKYqB:APA91bGaEDnXe2yRtSd827IhDSdMcinWo-2GqLsQN7Mnp7JCS8Nc8GJFWS5siAhjvxeF0RKuAmaHhvnc3bGoCz6Lhavi82JNS6731XmZ-VBqLVs92TatkIoocWbhSN7HBt-9Qh8tPmjQ'],
                "notification": {
                    "title": notificationParam.title,
                    "body": notificationParam.description,
                    "image": process.env.REACT_APP_IMG_URL+"view-media/1633319277678/dr-yousra_dermatologist.jpeg",
                    "content_available": "true",
                    "sound": "default"
                }
            }
            API.notification().sendPushNotification(pushNotification)
                .then(res => {
                    console.log(res);
                    toast.success('Sent succesfully');
                })

        }


    }

    const onEmojiClick = (event, emojiObject) => {
        console.log(emojiObject)
        setNotificationParam({ ...notificationParam, title: notificationParam.title.concat(emojiObject.emoji) })
    };

    const onBodyEmojiClick = (event, emojiObject) => {
        console.log(emojiObject)
        setNotificationParam({ ...notificationParam, description: notificationParam.description.concat(emojiObject.emoji) })
    };

    const handleChangeForServices = (event) => {
        setNotificationParam({ ...notificationParam, service: event.target.value })
    
      };

      const handleChangeForOffers = (event) => {
        setNotificationParam({ ...notificationParam, offer: event.target.value })
    
      };

      const handleChangeForCoupons = (event) => {
        setNotificationParam({ ...notificationParam, coupon: event.target.value })
    
      };

      const handleChangeForUsers = (event) => {
        setNotificationParam({ ...notificationParam, users: event.target.value })
    
      };

      const handleChangeForAllReceipeints= (event) => {
 
        setNotificationParam({ ...notificationParam, sendToAll: event.target.checked })
    
      };

    return (
        <div style={{ padding: "30px" }}>



            <Grid container spacing={1} alignItems="flex-end">

                <Grid fullWidth xs={11} item>
                    <TextField
                        autoFocus
                        name="title"
                        label="Notification Title"
                        value={notificationParam.title}
                        fullWidth
                        onChange={handleInputChange}
                        {...(errors.title && { error: true, helperText: errors.title })}
                    />
                </Grid>
                <Grid xs={1} item>
                    {showTitleEmoji &&
                    <div style={{position: 'absolute', right:0, zIndex: 10, marginTop: 30}}>

                        <Picker onEmojiClick={onEmojiClick} 
                          style={{marginTop: 30}}   
                        />
                    </div>
                    }
                    <EmojiEmotionsIcon
                        onClick={() => {setShowTitleEmoji(!showTitleEmoji);
                            setShowBodyEmoji(false)
                        }}
                    />
                </Grid>
            </Grid>

            <br /><br />


            <Grid container spacing={1} alignItems="flex-end">

                <Grid fullWidth xs={11} item>
                <TextField
                autoFocus
                name="description"
                label="Notification Message"
                value={notificationParam.description}
                fullWidth
                onChange={handleInputChange}
                multiline={true}
                rows={2}
                {...(errors.description && { error: true, helperText: errors.description })}
            />
                </Grid>
                <Grid xs={1} item>
                    {showBodyEmoji &&
                    <div style={{position: 'absolute', right:0, zIndex: 10, marginTop: 30}}>

                        <Picker onEmojiClick={onBodyEmojiClick} 
                          style={{marginTop: 30}}   
                        />
                    </div>
                    }
                    <EmojiEmotionsIcon
                        onClick={() => {setShowBodyEmoji(!showBodyEmoji);
                            setShowTitleEmoji(false)
                        }}
                    />
                </Grid>
            </Grid>
           
          
            <br /><br />

            <TextField
                autoFocus
                type="file"
                name="file"
                label="Image"
                variant="outlined"

                onChange={handleInputChangeForFileType}
                {...(errors.file && { error: true, helperText: errors.file })}
            />

            <img src={process.env.REACT_APP_IMG_URL+notificationParam.image} height="100" style={{ marginTop: "5px", float: "right" }} />

            <br /><br />

            <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Choose Service/Package</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={notificationParam.service}
              onChange={handleChangeForServices}
            >
              {props.services.map(item => (
                <MenuItem value={item.id}>{item.title}</MenuItem>
              ))}

            </Select>
          </FormControl>
       
            <br /><br />

            <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Choose Coupon</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={notificationParam.coupon}
              onChange={handleChangeForCoupons}
            >
              {props.coupons.map(item => (
                <MenuItem value={item.id}>{item.title}</MenuItem>
              ))}

            </Select>
          </FormControl>
       
            <br /><br />

            <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Choose Offer</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={notificationParam.offer}
              onChange={handleChangeForOffers}
            >
              {props.offers.map(item => (
                <MenuItem value={item.id}>{item.title}</MenuItem>
              ))}

            </Select>
          </FormControl>

          
       
            <br /><br />
            <FormControlLabel control={
                    <Checkbox
                    checked={notificationParam.sendToAll}
                    onChange={handleChangeForAllReceipeints}
                    inputProps={{ 'aria-label': 'controlled' }}
                    
                  />
                  } label="Send to all users" />
 <br /><br />
            <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Choose Receipeints</InputLabel>
            <Select
            disabled={notificationParam.sendToAll}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={notificationParam.users}
              onChange={handleChangeForUsers}
              multiple
            >
              {props.users.map(item => (
                <MenuItem value={item.id}>{item.title}</MenuItem>
              ))}

            </Select>
          </FormControl>
       
            <br /><br />


            <Button variant="contained" onClick={handleSubmit} color="secondary">
                Send
            </Button>

        </div>
    );
}

const mapStateToProps = state => ({
    services: state.service.services,
    offers: state.offer.offers,
    coupons: state.coupon.coupons,
    users: state.user.users
})

const mapActionToProps = {
    fetchServices : service.fetchAll,
    fetchOffers: offer.fetchAll,
    fetchCoupon: coupon.fetchAll,
    fetchUser: user.fetchAll
}

export default connect(mapStateToProps, mapActionToProps)(withStyles(styles)(NotificationCenter));