import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from '@material-ui/core';
import RichTextEditor from 'react-rte';
import EditIcon from '@material-ui/icons/Edit';
import { toast } from 'react-toastify';
import Grow from '@material-ui/core/Grow';
import API from "../../../utils/api";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const initialFormState = {
  id: null,
  image: "",
  name: {en:"", ar:""},
  categoryId: [],
  about: {en:"", ar:""},
  departments: [],
  seoh1: { en: "", ar: "" },
  seotitle: { en: "", ar: "" },
  seometa: { en: "", ar: "" },
}

const FormDialogEditBlog = (props) => {
  const [open, setOpen] = useState(false);
  const [blog, setBlog] = useState(initialFormState);
  const [errors, setErrors] = useState({})
  
  const [selectedLang, setSelectedLang] = useState('en')
  const [htmlDesc, setHtmlDesc] = useState(RichTextEditor.createValueFromString(props.dataBlog[4][selectedLang], 'html'))
  const handleClickOpen = () => {
console.log('props.dataBlog',props.dataBlog)
    setErrors({});
    setBlog({
      id: props.dataBlog[0],
      image: props.dataBlog[1],
      name: props.dataBlog[2]?props.dataBlog[2]:{en:"", ar:""},
      categoryId: props.dataBlog[3]?._id,
      about: props.dataBlog[4]?props.dataBlog[4]:{en:"", ar:""},
      departments: props.dataBlog[5]?props.dataBlog[5]:[],
      branch: props.dataBlog[6],
      seoh1: props.dataBlog[7] ? props.dataBlog[7] : { en: "", ar: "" },
      seotitle: props.dataBlog[8] ? props.dataBlog[8] : { en: "", ar: "" },
      seometa: props.dataBlog[9] ? props.dataBlog[9] : { en: "", ar: "" },
    })
    setHtmlDesc(RichTextEditor.createValueFromString(props.dataBlog[4][selectedLang], 'html'))

    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleInputChange = event => {
    const { name, value, lang } = event.target

    if (lang) {
      setBlog({
        ...blog,
        [name]: { ...blog[name], [selectedLang]: value }
      });

    } else {
      setBlog({ ...blog, [name]: value })
    }
  }

  useEffect(() => {
    
    if(blog.about.ar || blog.about.en){

    if(selectedLang==='ar' ){
      setHtmlDesc(RichTextEditor.createValueFromString(blog.about.ar, 'html'))
    }else{
      setHtmlDesc(RichTextEditor.createValueFromString(blog.about.en, 'html'))
    }
  }
  }, [selectedLang]);

  const handleHtmlInputChange = value => {

    setHtmlDesc(value)
  //  setBlog({ ...blog, about: value.toString('html') })
    setBlog({
      ...blog,
      ['about']: { ...blog['about'], [selectedLang]: value.toString('html') }
    });
  }

  const handleInputChangeForFileType = event => {
    if(event.target.files[0].size/ 1024 >150){
      toast.warning('maximum size allowed: 150kb');
      return
    }
    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());
    API.utility().upload(formdata)
    .then(res =>{
      setBlog({ ...blog, image: res.data.data.path })
    })

  }

  const validate = () => {
    let tempErrors = {};
    let formIsValid = true;

    if (!blog.image || blog.image.trim() === "") {
      formIsValid = false;
      tempErrors["image"] = "Cannot be empty";
      toast.warning('Image cannot be empty');
    }

    if (!blog.categoryId) {
      formIsValid = false;
      tempErrors["categoryId"] = "Cannot be empty";
      toast.warning('Category cannot be empty');
    }


    setErrors(tempErrors);
    return formIsValid;
  }

  const handleChange = (event) => {
    setBlog({ ...blog, categoryId:  event.target.value })
    
  };
  const handleBranchChangeForServices = (event) => {
    setBlog({ ...blog, branch: event.target.value })

  };
  const handleSubmit = (e) => {

    const onSuccess = () => {
      setOpen(false);
      toast.success('Data succesfully updated');
    }
    e.preventDefault();

    if (validate()) {

      props.update(blog.id, blog, onSuccess)
    }
  }

  const handleChangeForDepartments = (event) => {
    setBlog({ ...blog, departments: event.target.value })

  };

  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'lg'}
      >
        <DialogTitle id="form-dialog-title" style={{ padding: "30px 30px 0px 30px" }}>Edit blog</DialogTitle>

        <DialogContent style={{ padding: "30px 30px 10px 30px" }}>


<div className="row">
  <div className="col-lg-12 col-12 mb-4">
    <Button variant="contained" component="label">
      Upload Picture  <sub>&nbsp; 150kb max</sub>
      <input
        type="file"
        name="file"
        label="Image"
        accept=".png,.jpg,.gif"
        onChange={handleInputChangeForFileType}
        {...(errors.file && { error: true, helperText: errors.file })}
        hidden
      />
      
    </Button>
    
    <img src={process.env.REACT_APP_IMG_URL + blog.image} height="100" style={{ marginTop: "-30px", float: "right" }} />
  </div>
</div>

<div className="row border p-2">

  <div className="col-lg-12 col-12 mb-4">
    <FormControl className="float-end">
      <InputLabel id="demo-simple-select-label">Language</InputLabel>
      <Select
        value={selectedLang}
        onChange={(e) => setSelectedLang(e.target.value)}
      >
        <MenuItem value="en">English</MenuItem>
        <MenuItem value="ar">Arabic</MenuItem>
      </Select>
    </FormControl>
  </div>

  <div className="col-lg-12 col-12 mb-4">
    <TextField

      name="name"
      label="Name"
      value={blog?.name[selectedLang]}
      inputProps={{ 'lang': selectedLang }}
      fullWidth
      onChange={handleInputChange}
      {...(errors.name && { error: true, helperText: errors.name })}
    />
  </div>

  <div className="col-lg-12 col-12 mb-4">
    <p>About</p>
    <RichTextEditor
      value={htmlDesc}
      onChange={handleHtmlInputChange}
    />
  </div>
  <div className="col-lg-6 col-12 mb-4">
              <TextField
                name="seoh1"
                label="SEO h1"
      
                value={blog?.seoh1[selectedLang]}
                inputProps={{ 'lang': selectedLang }}
                fullWidth
                onChange={handleInputChange}
                {...(errors.seoh1 && { error: true, helperText: errors.seoh1 })}
              />
      
            </div>
      
            <div className="col-lg-6 col-12 mb-4">
              <TextField
                name="seotitle"
                label="SEO Title"
      
                value={blog?.seotitle[selectedLang]}
                inputProps={{ 'lang': selectedLang }}
                fullWidth
                onChange={handleInputChange}
                {...(errors.seotitle && { error: true, helperText: errors.seotitle })}
              />
      
            </div>
      
            <div className="col-lg-12 col-12 mb-4">
              <TextField
                name="seometa"
                label="SEO Meta"
      
                value={blog?.seometa[selectedLang]}
                inputProps={{ 'lang': selectedLang }}
                fullWidth
                onChange={handleInputChange}
                {...(errors.seometa && { error: true, helperText: errors.seometa })}
              />
      
            </div>
</div>
<br></br>
<FormControl
  fullWidth={true}
>
  <InputLabel id="demo-simple-select-label">Category</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={blog.categoryId}
    onChange={handleChange}
  
  >
    {props.categories.map(item => (
      <MenuItem value={item.id}>{item.title?.en}</MenuItem>
    ))}

  </Select>
</FormControl>


<br /><br />

<FormControl
  fullWidth={true}
>
  <InputLabel id="demo-simple-select-label">Departments</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={blog.departments}
    onChange={handleChangeForDepartments}
    multiple
  >
    {props.departments.map(item => (
      <MenuItem value={item.id}>{item.title.en}</MenuItem>
    ))}

  </Select>
</FormControl>


<br /><br />



<FormControl
  fullWidth={true}
>
  <InputLabel id="demo-simple-select-label">Choose Centre/Branch</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={blog.branch}
    onChange={handleBranchChangeForServices}

  >
    {props.branches.map(item => (
      <MenuItem value={item.id}>{item.name}</MenuItem>
    ))}

  </Select>
</FormControl>

<br /><br />


</DialogContent>

        <DialogActions style={{ padding: 30 }}>
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="secondary">
            Save
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}

export default FormDialogEditBlog;