import API from "../utils/api";
import _ from 'lodash'


export const ACTION_TYPES = {
    FEEDBACK_CREATE: 'FEEDBACK_CREATE',
    FEEDBACK_UPDATE: 'FEEDBACK_UPDATE',
    FEEDBACK_DELETE: 'FEEDBACK_DELETE',
    FEEDBACK_FETCH_ALL: 'FEEDBACK_FETCH_ALL',
    FEEDBACK_PAGINATION: 'FEEDBACK_PAGINATION',

}

export const fetchAll = () => dispatch => {
    API.feedback().fetchAll()
        .then(res => {
            dispatch({
                type: ACTION_TYPES.FEEDBACK_FETCH_ALL,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const Pagination = (page = 1, limit = 5, firstName = "", email = "") => dispatch => {
    API.feedback().fetchPagination(page, Math.abs(limit), firstName, email)
        .then(res => {

            dispatch({
                type: ACTION_TYPES.FEEDBACK_PAGINATION,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const fetchById = (id, onSuccess) => dispatch => {
    API.feedback().fetchById(id)
        .then(res => {
            console.log(res.data)
            dispatch({
                type: ACTION_TYPES.FEEDBACK_FETCH_ALL,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}
export const getByServiceId = (id) => dispatch => {
    API.feedback().getByServiceId(id)
        .then(res => {
            console.log('112233>>>>>>>',res.data)
            dispatch({
                type: ACTION_TYPES.FEEDBACK_FETCH_ALL,
                payload: res.data
            })
          
        })
        .catch(err => console.log(err))
}


export const create = (input) => dispatch => {
    API.feedback().create(input)
        .then(res => {
            dispatch({
                type: ACTION_TYPES.FEEDBACK_CREATE,
                payload: res.data
            })
       
        })
        .catch(err => console.log(err))
}

export const update = (id, input, onSuccess) => dispatch => {

    API.feedback().update(id, input)
        .then(res => {

            dispatch({
                type: ACTION_TYPES.FEEDBACK_UPDATE,
                payload: res.data
            })
             onSuccess()
        })
        .catch(err => console.log(err))
}



export const Delete = (id, onSuccess) => dispatch => {
    API.feedback().delete(id)
        .then(res => {

            dispatch({
                type: ACTION_TYPES.FEEDBACK_DELETE,
                payload: res.data.id
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

