import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { toast } from 'react-toastify';
import Grow from '@material-ui/core/Grow';
import API from "./../../../utils/api";
import DeleteIcon from "@material-ui/icons/Delete";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const initialFormState = {
  id: null,
  image: "",
  title: "",
  type: "coupon",
  price: 0,
  loyalty_points: "",
  doctorId: [],
  categoryId: "",
  promo_code: "",
  description: "",
  branch: "",
  unit: "",
  status: "",
  vat: "",
  featured: false,
  discount: 0,
  expiry: false,
  startDate: "",
  endDate: "",
  gallary: []


}

const FormDialogAddService = (props) => {
  const [open, setOpen] = useState(false);
  const [service, setService] = useState(initialFormState);
  const [errors, setErrors] = useState({})
  const [selectedLang, setSelectedLang] = useState('en')

  const handleClickOpen = () => {
    setErrors({});
    setService(initialFormState);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleInputChange = event => {
    const { name, value, lang } = event.target

    if (lang) {
      setService({
        ...service,
        [name]: { ...service[name], [lang]: value }
      });

    } else {
      setService({ ...service, [name]: value })
    }
  }

  const handleChangeForExpiry = (event) => {

    setService({ ...service, expiry: event.target.checked })

  };


  const handleInputChangeForFileType = event => {

    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());

    API.utility().upload(formdata)
      .then(res => {
        setService({ ...service, image: res.data.data.path })
      })

  }

  const handleChange = (event) => {
    setService({ ...service, categoryId: event.target.value })

  };

  const handleChangeForServices = (event) => {
    setService({ ...service, doctorId: event.target.value })

  };
  const handleBranchChangeForServices = (event) => {
    setService({ ...service, branch: event.target.value })

  };
  const handleUnitChangeForServices = (event) => {
    setService({ ...service, unit: event.target.value })

  };
  const handleChangeForstatus = (event) => {
    setService({ ...service, status: event.target.value })

  };
  const handleChangeForVat = (event) => {
    setService({ ...service, vat: event.target.value })

  };

  const handleChangeForFeatured = (event) => {

    setService({ ...service, featured: event.target.checked })

  };
  const validate = () => {
    let tempErrors = {};
    let formIsValid = true;

    if (!service.image || service.image.trim() === "") {
      formIsValid = false;
      tempErrors["image"] = "Cannot be empty";
    }

    // if (!service.title || service.title.trim() === "") {
    //   formIsValid = false;
    //   tempErrors["title"] = "Cannot be empty";
    // }

    if (!service.price) {
      formIsValid = false;
      tempErrors["price"] = "Cannot be empty";
    }

    if (!service.categoryId || service.categoryId.trim() === "") {
      formIsValid = false;
      tempErrors["categoryId"] = "Cannot be empty";
    }

    if (!service.doctorId) {
      formIsValid = false;
      tempErrors["doctorId"] = "Cannot be empty";
    }

    setErrors(tempErrors);
    return formIsValid;
  }

  const handleSubmit = (e) => {
    const onSuccess = () => {
      props.refresh()
      setOpen(false);
      toast.success('Data succesfully updated');
    }
    e.preventDefault();

    if (validate()) {
      props.create(service, onSuccess)
    }
  }


  const handleInputChangeForGallary = event => {

    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());
    API.utility().upload(formdata)
      .then(res => {
        setService({ ...service, gallary: [...service.gallary, res.data.data.path] })

        console.log(service)
      })

  }
  const deleteFromGallary = (i) => {

    let gal = service.gallary

    gal.splice(i, 1)

    setService({ ...service, gallary: gal })

    console.log(service)

  }

  const handleChangeForThumbnail = (img) => {

    setService({ ...service, image: img })

  };

  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen} >
        <AddCircleIcon style={{ fontSize: "40px" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'lg'}
      >
        <DialogTitle id="form-dialog-title" style={{ padding: "30px 30px 0px 30px" }}>Add coupon</DialogTitle>

        <DialogContent style={{ padding: "30px 30px 10px 30px" }}>

          <div className="row mt-4">
            <div className="col-lg-3 col-12 mb-4">
              {/* <div className="mb-1">Gallary</div>
              <TextField
                autoFocus
                type="file"
                name="file"
                className="border"
                onChange={handleInputChangeForGallary}
                {...(errors.file && { error: true, helperText: errors.file })}
              /> */}
              <Button variant="contained" component="label">
                Upload Picture in Gallary
                <input
                  type="file"
                  name="file"
                  label="Image"
                  onChange={handleInputChangeForGallary}
                  {...(errors.file && { error: true, helperText: errors.file })}
                  hidden
                />
              </Button>
            </div>
            <div className="col-lg-9 col-12">
              <div className="row">
                {service?.gallary?.map((img, i) => (
                  <div className="col-lg-3 col-3">

                    <img key={i} src={process.env.REACT_APP_IMG_URL + img} height="100" style={{ marginRight: "10px" }} />
                    <FormControlLabel control={
                      <Checkbox
                        checked={service.image === img}
                        onChange={() => handleChangeForThumbnail(img)}
                      />
                    } label="Set Thumb" />

                    <a onClick={() => deleteFromGallary(i)}>
                      <DeleteIcon />
                    </a>

                  </div>
                ))}
              </div>
            </div>
          </div>


          <div className="row border p-2 mb-4">

            <div className="col-lg-12 col-12 mb-4">
              <FormControl className="float-end">
                <InputLabel id="demo-simple-select-label">Language</InputLabel>
                <Select
                  value={selectedLang}
                  onChange={(e) => setSelectedLang(e.target.value)}
                >
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="ar">Arabic</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-12 col-12 mb-4">
              <TextField

                name="title"
                label="Title"
                value={service?.title[selectedLang]}
                inputProps={{ 'lang': selectedLang }}
                fullWidth
                onChange={handleInputChange}
              // {...(errors.title && { error: true, helperText: errors.title })}
              />


            </div>
            <div className="col-lg-12 col-12 mb-4">

              <TextField

                name="description"
                label="Description"
                value={service?.description[selectedLang]}
                inputProps={{ 'lang': selectedLang }}
                fullWidth
                onChange={handleInputChange}
                multiline={true}
                rows={3}
                {...(errors.description && { error: true, helperText: errors.description })}
              />



            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-6 col-12 mb-4">
              <TextField

                name="price"
                label="Price"
                value={service.price}
                type="number"
                fullWidth
                onChange={handleInputChange}

                {...(errors.price && { error: true, helperText: errors.price })}
              />
            </div>
            <div className="col-lg-6 col-12 mb-4">
              <TextField

                name="discount"
                label="Discount in %"
                value={service.discount}
                type="number"
                fullWidth
                onChange={handleInputChange}

                {...(errors.discount && { error: true, helperText: errors.discount })}
              />
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-6 col-12 mb-4">
              <TextField

                name="loyalty_points"
                label="Loyalty Points"
                type="number"
                value={service.loyalty_points}
                fullWidth
                onChange={handleInputChange}

                {...(errors.loyalty_points && { error: true, helperText: errors.loyalty_points })}
              />
            </div>
            <div className="col-lg-6 col-12 mb-4">
              <FormControl
                fullWidth={true}
              >
                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={service.categoryId}
                  onChange={handleChange}

                >
                  {props.categories.map(item => (
                    <MenuItem value={item.id}>{item.title?.en}</MenuItem>
                  ))}

                </Select>
              </FormControl>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-6 col-12 mb-4">

              <FormControl
                fullWidth={true}
              >
                <InputLabel id="demo-simple-select-label">Choose Doctor</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={service.doctorId}
                  onChange={handleChangeForServices}
                  multiple
                >
                  {props.doctors.filter((e) => e.role === 'doctor').map(item => (
                    <MenuItem value={item.id}>{item.name?.en}</MenuItem>
                  ))}

                </Select>
              </FormControl>
            </div>
            <div className="col-lg-6 col-12 mb-4">
              <FormControl
                fullWidth={true}
              >
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={service.status}
                  onChange={handleChangeForstatus}

                >

                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>


                </Select>
              </FormControl>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-6 col-12 mb-4">
              <FormControl
                fullWidth={true}
              >
                <InputLabel id="demo-simple-select-label">Is VAT Enabled?</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={service.vat}
                  onChange={handleChangeForVat}

                >

                  <MenuItem value="yes">Yes</MenuItem>
                  <MenuItem value="no">No</MenuItem>


                </Select>
              </FormControl>
            </div>
            <div className="col-lg-6 col-12 mb-4">
              <FormControl
                fullWidth={true}
              >
                <InputLabel id="demo-simple-select-label">Choose Centre/Branch</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={service.branch}
                  onChange={handleBranchChangeForServices}

                >
                  {props.branches.map(item => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  ))}

                </Select>
              </FormControl>
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-6 col-12 mb-4">
              <FormControl
                fullWidth={true}
              >
                <InputLabel id="demo-simple-select-label">Choose Unit</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={service.unit}
                  onChange={handleUnitChangeForServices}

                >
                  {props.units.map(item => (
                    <MenuItem value={item.id}>{item.title}</MenuItem>
                  ))}

                </Select>
              </FormControl>
            </div>
            <div className="col-lg-6 col-12 mb-4">
              <FormControlLabel control={
                <Checkbox
                  checked={service.featured}
                  onChange={handleChangeForFeatured}
                  inputProps={{ 'aria-label': 'controlled' }}

                />
              } label="Featured" />

              <FormControlLabel control={
                <Checkbox
                  checked={service.expiry}
                  onChange={handleChangeForExpiry}
                  inputProps={{ 'aria-label': 'controlled' }}

                />
              } label="Set Expiry" />
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-lg-6 col-12 mb-4">
              <TextField
                type="datetime-local"
                disabled={!service.expiry}
                InputLabelProps={{
                  shrink: true,
                }}
                name="startDate"
                label="Start date"
                defaultValue={service.startDate}
                value={service.startDate}
                fullWidth
                onChange={handleInputChange}
                {...(errors.startDate && { error: true, helperText: errors.startDate })}
              />
            </div>
            <div className="col-lg-6 col-12 mb-4">
              <TextField
                type="datetime-local"
                disabled={!service.expiry}
                InputLabelProps={{
                  shrink: true,
                }}
                name="endDate"
                label="End date"
                defaultValue={service.endDate}
                value={service.endDate}
                fullWidth
                onChange={handleInputChange}
                {...(errors.endDate && { error: true, helperText: errors.endDate })}
              />
            </div>
          </div>

        </DialogContent>

        <DialogActions style={{ padding: 30 }}>
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="secondary">
            Save
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}

export default FormDialogAddService;