import React, { useEffect, useState } from "react";
import { Paper, withStyles } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import * as actions from "../../../actions/service";
import * as doctor from "../../../actions/doctor";
import * as category  from "../../../actions/category";
import * as branche  from "../../../actions/branche";
import * as unit  from "../../../actions/unit";
import FormDialogAddService from "../formDialog/FormDialogAddService";
import FormDialogEditService from "../formDialog/FormDialogEditService";
import FormDialogDeleteService from "../formDialog/FormDialogDeleteService";

const styles = theme => ({
    paperTable: {
        padding: theme.spacing(0),
    }
})

const ServiceTable = ({ classes, ...props }) => {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(15)

    useEffect(() => {     
        props.fetchCategories();
        props.fetchDoctors();
        props.fetchBranches();
        props.fetchUnits();
        props.fetchProducts(1, rowsPerPage)
    }, [])

    const handleChangePage = async (newPage) => {
        await setPage(newPage);
        props.fetchProducts(newPage + 1, rowsPerPage)
    };

    const handleChangeRowsPerPage = async (rowsPerPage) => {
        await setRowsPerPage(rowsPerPage);
        await setPage(0);
        props.fetchProducts(1, rowsPerPage)
    };

    const handleSearch = async (searchText) => {
        await setPage(0);
        props.fetchProducts(1, rowsPerPage,  searchText, null)
    };

    const handleFilterChange = async (title,price) => {
        await setPage(0);
     
        props.fetchProducts(1, rowsPerPage, title,price[0])
    };

    const refresh = async () => {
        await setPage(0);
        props.fetchProducts(1, rowsPerPage)
    }
    
    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                display: false,
                filter: false,
                sort: false,
            }
        },
        {
            name: "image",
            label: "Image",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
         
        },
        {
            // left side of first column is too close with the container, give more space on it
            name: "title",
            label: "Title",
            options: {
                filter: true,
                sort: false,
                customHeadRender: (columnMeta, handleToggleColumn) => {
                    return (
                        <th key={columnMeta.index} 
                            style={{
                                paddingLeft: "31px", 
                                fontWeight:500, 
                                borderBottom: "1px solid rgba(224, 224, 224, .5)" 
                                }}
                        >
                            <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-start"}}>
                                {columnMeta.label}
                            </div>
                        </th>
                    );
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <span style={{marginLeft:15}}>
                            {value}
                        </span>
                    );
                }
            },
            
        },
        {
            name: "type",
            label: "Type",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "price",
            label: "Price",
            options: {
                filter: true,
                sort: false,
            }
        },
    
        {
            name: "loyalty_points",
            label: "Loyalty Points",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "doctorId",
            label: "doctorId",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "categoryId",
            label: "Category",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "promo_code",
            label: "Promo code",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "description",
            label: "Description",
            options: {
                filter: false,
                sort: false,
                
            }
        },
        {
            name: "branch",
            label: "Branch",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "unit",
            label: "Unit",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "vat",
            label: "Vat",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "featured",
            label: "featured",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "discount",
            label: "discount",
            options: {
                filter: false,
                sort: false,
                display: false,
            }
        },
        {
            name: "",
            options: {
                filter: false,
                sort: true,
                empty: true,
                customHeadRender: (columnMeta, handleToggleColumn) => {
                    return (
                        <th key={columnMeta.index} style={{paddingRight: "16px"}}>
                            <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end"}}>
                                <FormDialogAddService component={Paper}  
                                    create={props.create}
                                    refresh={refresh}
                                    categories ={ props.categories}
                                    doctors ={ props.doctors}
                                    branches ={ props.branches}
                                    units ={ props.units}
                                />
                            </div>
                        </th>
                    );
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end"}}>
                            <FormDialogEditService
                                dataService={tableMeta.rowData}
                                update={props.update}
                                categories ={ props.categories}
                                doctors ={ props.doctors}
                                branches ={ props.branches}
                                units ={ props.units}
                            />
                            <FormDialogDeleteService 
                                dataService={tableMeta.rowData}
                                delete={props.delete}
                                refresh={refresh}
                            
                            />
                        </div>
                    );
                }
            }
        }
    ];

    const options = {
        filter: false,
        filterType: 'textField',
        responsive: 'stacked',
        selectableRows: false,
        rowsPerPageOptions: [5, 10, 25],
        serverSide: true,
        viewColumns: false,
        print: false,
        download: false,

        rowsPerPage: rowsPerPage,
        count: props?.meta?.totalDocs || 0,
        page: page,

        onTableChange: (action, tableState) => {
            switch (action) {
              case 'changePage':
                handleChangePage(tableState.page)
                break;

            case 'changeRowsPerPage':
                handleChangeRowsPerPage(tableState.rowsPerPage)
                break;

            case 'search':
                handleSearch(tableState.searchText)
                break;

            case 'filterChange':
                handleFilterChange(tableState.filterList[2], tableState.filterList[4])
                break;
            
            case 'resetFilters':
                handleSearch("")
                break;
                 
              default:
                break;
            }
        },
    };
    
    return (
        <MUIDataTable className={classes.paperTable}
            data={props.services}
            columns={columns}
            options={options}
        />
    );
}

const mapStateToProps = state => ({
    services: state?.service?.services,
    meta: state?.service?.metaService,
    categories: state.category.categories,
    doctors: state.doctor.doctors,
    branches: state.branche.branches,
    units: state.unit.units
})

const mapActionToProps = {
    fetchAll: actions.fetchAll,
    fetchProducts: actions.productPagination,
    create: actions.create,
    update: actions.update,
    delete: actions.Delete,
    fetchCategories : category.fetchAll,
    fetchDoctors : doctor.fetchAll,
    fetchBranches : branche.fetchAll,
    fetchUnits : unit.fetchAll
}

export default connect(mapStateToProps, mapActionToProps)(withStyles(styles)(ServiceTable));