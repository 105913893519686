import API from "../utils/api";

export const ACTION_TYPES = {
    DEPARTMENT_CREATE: 'DEPARTMENT_CREATE',
    DEPARTMENT_UPDATE: 'DEPARTMENT_UPDATE',
    DEPARTMENT_DELETE: 'DEPARTMENT_DELETE',
    DEPARTMENT_FETCH: 'DEPARTMENT_FETCH',
    DEPARTMENT_FETCH_ALL: 'DEPARTMENT_FETCH_ALL',
    DEPARTMENT_PAGINATION: 'DEPARTMENT_PAGINATION'
}

export const fetchAll = () => dispatch => {
    API.department().fetchAll()
        .then(res => {
      
            dispatch({
                type: ACTION_TYPES.DEPARTMENT_FETCH_ALL,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const Pagination = (page = 1, limit = 5, title = "") => dispatch => {
    API.department().fetchPagination(page, Math.abs(limit), title)
        .then(res =>{
         
            dispatch({
                type: ACTION_TYPES.DEPARTMENT_PAGINATION,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const fetchById = (id, onSuccess) => dispatch => {
    API.department().fetchById(id)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.DEPARTMENT_FETCH,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}

export const create = (input, onSuccess) => dispatch => {
    API.department().create(input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.DEPARTMENT_CREATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const update = (id, input, onSuccess) => dispatch => {
   
    API.department().update(id, input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.DEPARTMENT_UPDATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const Delete = (id, onSuccess) => dispatch => {
    API.department().delete(id)
        .then(res =>{
         
            dispatch({
                type: ACTION_TYPES.DEPARTMENT_DELETE,
                payload: res.data.id
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}