import React, { useEffect, useState } from "react";
import { Chip, withStyles } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { connect } from "react-redux";
import * as actions from "../../../actions/doctorbooking";
import * as doctors from "../../../actions/doctor";
import moment from 'moment';

import FormDialogEditDoctorbooking from "../formDialog/FormDialogEditDoctorbooking";
import FormDialogDeleteDoctorbooking from "../formDialog/FormDialogDeleteDoctorbooking";

const styles = theme => ({
    paperTable: {
        padding: theme.spacing(0),
    }
})

const DoctorbookingTable = ({ classes, ...props }) => {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(15)

    useEffect(() => {
        props.fetchPagination(1, rowsPerPage)
        props.fetchAllDoctors()
    }, [])

    const handleChangePage = async (newPage) => {
        await setPage(newPage);
        props.fetchPagination(newPage + 1, rowsPerPage)
    };

    const handleChangeRowsPerPage = async (rowsPerPage) => {
        await setRowsPerPage(rowsPerPage);
        await setPage(0);
        props.fetchPagination(1, rowsPerPage)
    };

    const handleSearch = async (searchText) => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, searchText, searchText)
    };

    const handleFilterChange = async (title,description) => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage, title, description)
    };

    const refresh = async () => {
        await setPage(0);
        props.fetchPagination(1, rowsPerPage)
    }
   
    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                display: false,
                filter: false,
                sort: false,
            }
        },
        {
            name: "doctor",
            label: "Doctor",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {return value?.map((doctor, index) =>
                    <span key={index}>
                      {doctor.name?.en}
                    </span>
                )} 
                  
            }
        },
        {
            name: "services",
            label: "Services",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {return value?.map((service, index) =>
                    <span key={index}>
                      {service.title?.en}
                    </span>
                )} 
                  
            }
        },
        {
            name: "type",
            label: "Type",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) =>  {
                    return <Chip className="text-capitalize" variant="outlined" size="small" color={value==="doctor"?"secondary":"primary"} label={value} />

                }
            }
        },
        {
            name: "customer.firstName",
            label: "Customer",
            options: {
                filter: true,
                sort: false,
            }
        },
        {
            name: "customer.lastName",
            label: "Customer",
            options: {
                filter: true,
                sort: false,
                display: false
            }
        },
        {
            name: "customer.email",
            label: "email",
            options: {
                filter: true,
                sort: false,
                display: false
            }
        },
        {
            name: "customer.mobile",
            label: "mobile",
            options: {
                filter: true,
                sort: false,
                display: false
            }
        },
        {
            name: "bookingDate",
            label: "Booking Date",
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) =>  {
                    return moment(value).format('ll');
                }
            }
        },
        {
            name: "bookingTime",
            label: "Booking Time",
           
        },

        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: false,
            }
        },
       
        {
            name: "",
            options: {
                filter: false,
                sort: false,
                empty: true,
                
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end"}}>
                             <FormDialogEditDoctorbooking
                                dataDoctorbooking={tableMeta.rowData}
                                update={props.update}
                                doctorbookings ={ props.doctorbookings}
                                doctors = {props.doctors}
                            /> 
                            <FormDialogDeleteDoctorbooking 
                                dataDoctorbooking={tableMeta.rowData}
                                delete={props.delete}
                                refresh={refresh}
                            />
                        </div>
                    );
                }
            }
        }
    ];

    const options = {
        filter: false,
        filterType: 'textField',
        responsive: 'stacked',
        selectableRows: false,
        rowsPerPageOptions: [5, 10, 25],
        serverSide: true,
        viewColumns: false,
        print: false,
        download: false,

        rowsPerPage: rowsPerPage,
        count: props?.meta?.totalDocs || 0,
        page: page,

        onTableChange: (action, tableState) => {
            switch (action) {
              case 'changePage':
                handleChangePage(tableState.page)
                break;

            case 'changeRowsPerPage':
                handleChangeRowsPerPage(tableState.rowsPerPage)
                break;

            case 'search':
                handleSearch(tableState.searchText)
                break;

            case 'filterChange':
                handleFilterChange(tableState.filterList[1], tableState.filterList[2])
                break;
            
            case 'resetFilters':
                handleSearch("")
                break;
                 
              default:
                break;
            }
        },
    };
    
    return (
        <MUIDataTable className={classes.paperTable}
            data={props.doctorbookings}
            columns={columns}
            options={options}
        />
    );
}

const mapStateToProps = state => ({
    doctorbookings: state?.doctorbooking?.doctorbookings,
    meta: state?.doctorbooking?.metaDoctorbooking,
    doctors: state?.doctor?.doctors,
})

const mapActionToProps = {
    fetchPagination: actions.Pagination,
    create: actions.create,
    update: actions.update,
    delete: actions.Delete,
    fetchAllDoctors: doctors.fetchAll
}

export default connect(mapStateToProps, mapActionToProps)(withStyles(styles)(DoctorbookingTable));