import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import AddCircleIcon from '@material-ui/icons/AddCircle';
import { toast } from 'react-toastify';
import Grow from '@material-ui/core/Grow';
import { IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from '@material-ui/core';
import API from "./../../../utils/api";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const initialFormState = {
  id: null,
  image: "",
  discount: "",
  title: "",
  description: "",
  services: new Date(),
  startDate: new Date(),
  endDate: "",
  expiry: false,
  featured: false
}

const FormDialogAddCoupon = (props) => {
  const [open, setOpen] = useState(false);
  const [coupon, setCoupon] = useState(initialFormState);
  const [errors, setErrors] = useState({})

  const handleClickOpen = () => {
    setErrors({});
    setCoupon(initialFormState);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleInputChange = event => {
    const { name, value } = event.target
    setCoupon({ ...coupon, [name]: value })
  }

  const handleChangeForExpiry = (event) => {

    setCoupon({ ...coupon, expiry: event.target.checked })

  };

  const handleChangeForFeatured = (event) => {

    setCoupon({ ...coupon, featured: event.target.checked })

  };

  const validate = () => {
    let tempErrors = {};
    let formIsValid = true;


    if (!coupon.title || coupon.title.trim() === "") {
      formIsValid = false;
      tempErrors["title"] = "Cannot be empty";
    }

    if (!coupon.discount) {
      formIsValid = false;
      tempErrors["discount"] = "Cannot be empty";
    }
    setErrors(tempErrors);
    return formIsValid;
  }

  const handleSubmit = (e) => {
    const onSuccess = () => {
      props.refresh()
      setOpen(false);
      toast.success('Data succesfully updated');
    }
    e.preventDefault();

    if (validate()) {
      props.create(coupon, onSuccess)
    }
  }

  const handleChangeForServices = (event) => {
    setCoupon({ ...coupon, services: event.target.value })

  };
  const handleInputChangeForFileType = event => {
 
    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());
    API.utility().upload(formdata)
    .then(res =>{
      setCoupon({ ...coupon, image: res.data.data.path })
    })

  }

  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen} >
        <AddCircleIcon style={{ fontSize: "40px" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle id="form-dialog-title" style={{ padding: "30px 30px 0px 30px" }}>Add coupon</DialogTitle>

        <DialogContent style={{ padding: "30px 30px 10px 30px" }}>


        <TextField
            autoFocus
            type="file"
            name="file"
            label="Image"
            variant="outlined"

            onChange={handleInputChangeForFileType}
            {...(errors.file && { error: true, helperText: errors.file })}
          />  
          
          <img src={process.env.REACT_APP_IMG_URL+coupon.image} height="100" style={{ marginTop: "-30px", float: "right" }} />

          <TextField
            
            name="title"
            label="Title"
            value={coupon.title}
            fullWidth
            onChange={handleInputChange}
            {...(errors.title && { error: true, helperText: errors.title })}
          />
          <br /><br />

          <TextField
            
            name="discount"
            label="Discount"
            value={coupon.discount}
            type="number"
            fullWidth
            onChange={handleInputChange}
            {...(errors.discount && { error: true, helperText: errors.discount })}
          />
          <br /><br />
          <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Choose Service/Package</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={coupon.services}
              onChange={handleChangeForServices}
            >
              {props.services.map(item => (
                <MenuItem value={item.id}>{item.title}</MenuItem>
              ))}

            </Select>
          </FormControl>
          <br /><br />

          <TextField
            name="description"
            label="Description"
            value={coupon.description}
            fullWidth
            onChange={handleInputChange}
            multiline={true}
            rows={2}
            {...(errors.description && { error: true, helperText: errors.description })}
          />
          <br /><br />
          <FormControlLabel control={
            <Checkbox
              checked={coupon.expiry}
              onChange={handleChangeForExpiry}
              inputProps={{ 'aria-label': 'controlled' }}

            />
          } label="Set Expiry" />
          <br /><br />
          
          <TextField
            type="datetime-local"
            disabled={!coupon.expiry}
            InputLabelProps={{
              shrink: true,
            }}
            name="startDate"
            label="Start date"
            defaultValue={coupon.startDate}
            value={coupon.startDate}
            fullWidth
            onChange={handleInputChange}
            {...(errors.startDate && { error: true, helperText: errors.startDate })}
          />
          <br /><br />

          <TextField
            type="datetime-local"
            disabled={!coupon.expiry}
            InputLabelProps={{
              shrink: true,
            }}
            name="endDate"
            label="End date"
            defaultValue={coupon.endDate}
            value={coupon.endDate}
            fullWidth
            onChange={handleInputChange}
            {...(errors.endDate && { error: true, helperText: errors.endDate })}
          />
          <br /><br />
          <FormControlLabel control={
            <Checkbox
              checked={coupon.featured}
              onChange={handleChangeForFeatured}
              inputProps={{ 'aria-label': 'controlled' }}

            />
          } label="Featured" />
          <br /><br />

        </DialogContent>

        <DialogActions style={{ padding: 30 }}>
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="secondary">
            Save
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}

export default FormDialogAddCoupon;