import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from '@material-ui/core';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import { toast } from 'react-toastify';
import Grow from '@material-ui/core/Grow';
import API from "./../../../utils/api";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const initialFormState = {
  id: null,
  image: "",
  title: "",
  type: "item",
  price: 0,
  loyalty_points: "",
  doctorId: [],
  categoryId: "",
  promo_code: "",
  description: "",
  branch: "",
  unit: "",
  status: "",
  vat: "",
  featured: false,
  discount: 0

}

const FormDialogAddService = (props) => {
  const [open, setOpen] = useState(false);
  const [service, setService] = useState(initialFormState);
  const [errors, setErrors] = useState({})

  const handleClickOpen = () => {
    setErrors({});
    setService(initialFormState);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleInputChange = event => {

    const { name, value } = event.target
    setService({ ...service, [name]: value })
  }


  const handleInputChangeForFileType = event => {

    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());

    API.utility().upload(formdata)
      .then(res => {
        setService({ ...service, image:  res.data.data.path })
      })

  }

  const handleChange = (event) => {
    setService({ ...service, categoryId: event.target.value })

  };

  const handleChangeForServices = (event) => {
    setService({ ...service, doctorId: event.target.value })

  };
  const handleBranchChangeForServices = (event) => {
    setService({ ...service, branch: event.target.value })

  };
  const handleUnitChangeForServices = (event) => {
    setService({ ...service, unit: event.target.value })

  };
  const handleChangeForstatus = (event) => {
    setService({ ...service, status: event.target.value })

  };
  const handleChangeForVat = (event) => {
    setService({ ...service, vat: event.target.value })

  };

  const handleChangeForFeatured = (event) => {

    setService({ ...service, featured: event.target.checked })

  };
  const validate = () => {
    let tempErrors = {};
    let formIsValid = true;

    if (!service.image || service.image.trim() === "") {
      formIsValid = false;
      tempErrors["image"] = "Cannot be empty";
    }

    if (!service.title || service.title.trim() === "") {
      formIsValid = false;
      tempErrors["title"] = "Cannot be empty";
    }

    if (!service.price) {
      formIsValid = false;
      tempErrors["price"] = "Cannot be empty";
    }

    if (!service.categoryId || service.categoryId.trim() === "") {
      formIsValid = false;
      tempErrors["categoryId"] = "Cannot be empty";
    }

    if (!service.doctorId) {
      formIsValid = false;
      tempErrors["doctorId"] = "Cannot be empty";
    }

    setErrors(tempErrors);
    return formIsValid;
  }

  const handleSubmit = (e) => {
    const onSuccess = () => {
      props.refresh()
      setOpen(false);
      toast.success('Data succesfully updated');
    }
    e.preventDefault();

    if (validate()) {
      props.create(service, onSuccess)
    }
  }

  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen} >
        <AddCircleIcon style={{ fontSize: "40px" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle id="form-dialog-title" style={{ padding: "30px 30px 0px 30px" }}>Add Product</DialogTitle>

        <DialogContent style={{ padding: "30px 30px 10px 30px" }}>

          <TextField
            autoFocus
            type="file"
            name="file"
            label="Image"
            variant="outlined"

            onChange={handleInputChangeForFileType}
            {...(errors.file && { error: true, helperText: errors.file })}
          />

          <img src={process.env.REACT_APP_IMG_URL+service.image} height="100" style={{ float: "right", marginTop: "-30px" }} />

          <TextField

            name="title"
            label="Title"
            value={service.title}
            fullWidth
            onChange={handleInputChange}
            {...(errors.title && { error: true, helperText: errors.title })}
          />
          <br /><br />

          <TextField

            name="price"
            label="Price"
            value={service.price}
            type="number"
            fullWidth
            onChange={handleInputChange}
            multiline={true}
            rows={2}
            {...(errors.price && { error: true, helperText: errors.price })}
          />
          <br /><br />
          <TextField

            name="discount"
            label="Discount in %"
            value={service.discount}
            type="number"
            fullWidth
            onChange={handleInputChange}
            multiline={true}
            rows={2}
            {...(errors.discount && { error: true, helperText: errors.discount })}
          />
          <br /><br />

          <TextField

            name="loyalty_points"
            label="Loyalty Points"
            type="number"
            value={service.loyalty_points}
            fullWidth
            onChange={handleInputChange}
            multiline={true}
            rows={2}
            {...(errors.loyalty_points && { error: true, helperText: errors.loyalty_points })}
          />
          <br /><br />

          <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={service.categoryId}
              onChange={handleChange}

            >
              {props.categories.map(item => (
                <MenuItem value={item.id}>{item.title}</MenuItem>
              ))}

            </Select>
          </FormControl>

          <br /><br />

          <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Choose Doctor</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={service.doctorId}
              onChange={handleChangeForServices}
              multiple
            >
              {props.doctors.map(item => (
                <MenuItem value={item.id}>{item.name.en}</MenuItem>
              ))}

            </Select>
          </FormControl>

          <br /><br />

          <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={service.status}
              onChange={handleChangeForstatus}

            >

              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>


            </Select>
          </FormControl>

          <br /><br />

          <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Is VAT Enabled?</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={service.vat}
              onChange={handleChangeForVat}

            >

              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>


            </Select>
          </FormControl>

          <br /><br />

          <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Choose Centre/Branch</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={service.branch}
              onChange={handleBranchChangeForServices}

            >
              {props.branches.map(item => (
                <MenuItem value={item.id}>{item.name}</MenuItem>
              ))}

            </Select>
          </FormControl>

          <br /><br />

          <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Choose Unit</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={service.unit}
              onChange={handleUnitChangeForServices}

            >
              {props.units.map(item => (
                <MenuItem value={item.id}>{item.title}</MenuItem>
              ))}

            </Select>
          </FormControl>


          <br /><br />
          <TextField

            name="description"
            label="Description"
            value={service.description}
            fullWidth
            onChange={handleInputChange}
            multiline={true}
            rows={2}
            {...(errors.description && { error: true, helperText: errors.description })}
          />

          <br /><br />

          <FormControlLabel control={
            <Checkbox
              checked={service.featured}
              onChange={handleChangeForFeatured}
              inputProps={{ 'aria-label': 'controlled' }}

            />
          } label="Featured" />
          <br /><br />

        </DialogContent>

        <DialogActions style={{ padding: 30 }}>
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="secondary">
            Save
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}

export default FormDialogAddService;