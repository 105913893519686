import React, { useState, useContext } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { toast } from 'react-toastify';
import Grow from '@material-ui/core/Grow';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Drawer from '@material-ui/core/Drawer';
import Tooltip from '@material-ui/core/Tooltip';

import API from '../../../utils/api'
import moment from 'moment';
import { AuthContext } from "../../../context/AuthContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const initialFormState = {
  id: null,
  customer_name: "",
  customer_email: "",
  customer_mobile: "",
  customer_id: "",
  transaction_no: "",
  transaction_amount: 0,
  subTotal: 0,
  discount: 0,
  promocode: "",
  orderedServices: {},
  transaction_status: "",
  order_status: "",
  note: "",
  createdOn: "",
  scheduledServices: {},
  orderID: ''
}
const timeSlots = [
  { label: '9.00 AM', key: '9.00', section: false },
  { label: '9.30 AM', key: '9.30', section: false },
  { label: '10.00 AM', key: '10.00', section: false },
  { label: '10.30 AM', key: '10.30', section: false },
  { label: '11.00 AM', key: '11.00', section: false },
  { label: '11.30 AM', key: '11.30', section: false },
  { label: '12.00 PM', key: '12.00', section: false },
  { label: '12.30 PM', key: '12.30', section: false },
  { label: '1.00 PM', key: '13.00', section: false },
  { label: '1.30 PM', key: '13.30', section: false },
  { label: '2.00 PM', key: '14.00', section: false },
  { label: '2.30 PM', key: '14.30', section: false },
  { label: '3.00 PM', key: '15.00', section: false },
  { label: '3.30 PM', key: '15.30', section: false },
  { label: '4.00 PM', key: '16.00', section: false },
  { label: '4.30 PM', key: '16.30', section: false },
  { label: '5.00 PM', key: '17.00', section: false },
  { label: '5.30 PM', key: '17.30', section: false },
  { label: '6.00 PM', key: '18.00', section: false },
  { label: '6.30 PM', key: '18.30', section: false },
  { label: '7.00 PM', key: '19.00', section: false },
  { label: '7.30 PM', key: '19.30', section: false },
  { label: '8.00 PM', key: '20.00', section: false },
  { label: '8.30 PM', key: '20.30', section: false },
  { label: '9.00 PM', key: '21.00', section: false },
  { label: '9.30 PM', key: '21.30', section: false }
]

const FormDialogEditOrder = (props) => {
  const [open, setOpen] = useState(false);
  const [order, setOrder] = useState(initialFormState);
  const [errors, setErrors] = useState({})
  const [services, setServices] = useState([])
  const [sendNotfication, setSendNotfication] = useState(false)
  const [sendDateTimeNotfication, setSendDateTimeNotfication] = useState(false)
  const [sendOrderStatusNotfication, setSendOrderStatusNotfication] = useState(false)
  const [slots, setSlots] = useState(timeSlots);
  const [bookings, setBookings] = useState({});
  const [currentBookedSlots, setCurrentBookedSlots] = useState([]);
  const [fcm, setFcm] = useState('');
  const [fcmdesc, setFcmDesc] = useState('');
  const [anchor, setAnchor] = useState(false)
  const classes = useStyles();
  const [logs, setLogs] = useState([])
  const [history, setHistory] = useState([])
  const { getAuthUser} = useContext(AuthContext)
  const authUser = getAuthUser()

  const handleClickOpen = () => {
    //console.log('112', props)
    setErrors({});
    setOrder({
      id: props.dataOrder[0],
      transaction_no: props.dataOrder[1],
      customer_name: props.dataOrder[2],
      customer_email: props.dataOrder[3],
      customer_mobile: props.dataOrder[4],
      customer_id: props.dataOrder[5],

      transaction_amount: props.dataOrder[6],
      subTotal: props.dataOrder[7],
      discount: props.dataOrder[8],
      promocode: props.dataOrder[9],
      orderedServices: props.dataOrder[10],
      transaction_status: props.dataOrder[11],
      order_status: props.dataOrder[12],
      scheduleDate: props.dataOrder[13],
      note: props.dataOrder[14],
      createdOn: props.dataOrder[15],
      scheduledServices: props.dataOrder[16],
      orderID: props.dataOrder[17]
    })
    setOpen(true);
    setBookings(props.dataOrder[16] ? props.dataOrder[16] : props.dataOrder[10])
    getCartItem();

    API.log().fetchAll({
      moduleId: props.dataOrder[0],
      type: "order",
    })
    .then(res => {

      setHistory(res.data)
    })
  }

  const handleClose = () => {
    setOpen(false);

  }

  const handleInputChange = event => {

    const { name, value } = event.target
    setOrder({ ...order, [name]: value })

    if (name === "scheduleDate") {
      setSendDateTimeNotfication(true)
      setSendNotfication(true)
    }
    if (name === "order_status") {
      setSendOrderStatusNotfication(true)
      setSendNotfication(true)
    }
    API.order().fetchById(order.id)
      .then(res => {

        setFcm(res.data.customer_id[0].fcmToken)
      })
  }
  const onDateChange = async (event, id, index, isDate, otherValue,serviceid) => {
    setSendNotfication(true)
   
    const { name, value } = event.target
    let date
    let time
    if (isDate) {

      date = value;
      time = otherValue
    } else {
      date = otherValue;
      time = value
    }
    setFcmDesc('Appointment time updated.')
    // console.log(date,new Date().toISOString().split('T')[0])
    // setSlots(timeSlots)


    await API.order().getAvailableTimeSlots({
      bookingDate: date,
    })
      .then(res => {
        console.log('000>>', res.data)
        setCurrentBookedSlots(res.data)
        // let arr = res.data
        // let sl = timeSlots;

        // sl.forEach((ele, index) => {
        //   sl[index].section = false
        //   if(arr.includes(ele.key)){
        //     sl[index].section= true
        //   }
        //   if(date===new Date().toISOString().split('T')[0]){
        //     if (parseInt(ele.key)<parseInt(moment(new Date()).format("HH:mm"))+0.60) {
        //       sl[index].section = true
        //     }

        //   }
        // })
        // setSlots(sl)


      })
      .catch(err => console.log(err))



    let booking = bookings
    if(serviceid){
      
      let empty
      if(booking[id]?.constructor.name === 'Object'){
        empty = booking[id]
      }else{
        empty = {}
      }
       empty[serviceid]= empty[serviceid]? empty[serviceid]:[]
       empty[serviceid][index]={
        "date": date,
        "time": time,
        "status": 'Pending'
      }
      booking[id] = empty
    }else{

    
    if (Array.isArray(booking[id])) {
      let empty = booking[id]
      empty[index] = {...empty[index],
        "date": date,
        "time": time
      }
      booking[id] = empty
    } else {
      let empty = []
      for (var i = 0; i < booking[id]; i++) {
        if (index === i) {
          empty.push({
            "date": date,
            "time": time,
            "status": 'Pending'
          })
        } else {
          empty.push({
            "date": '',
            "time": '',
            "status": 'Pending'
          })
        }

      }
      booking[id] = empty
    }
   
  }
  console.log('009',booking)
  
    await API.order().fetchById(order.id)
      .then(res => {
        //console.log('1112>>', res.data.customer_id[0].fcmToken)
        setBookings(booking)
        setSendDateTimeNotfication(true)
        setSendNotfication(true)
        setOrder({
          ...order,
          orderedServices: res.data.orderedServices,
          scheduledServices: booking,

        })
        setFcm(res.data.customer_id[0].fcmToken)
      })

    
  }

  const onOrderStatusChange = (event, id, index,time,date,serviceid) => {
   
    console.log(event.target.value, id, index)
    const { value } = event.target
 
    let booking = bookings

    if(serviceid){
      let empty
      if(booking[id]?.constructor.name === 'Object'){
        empty = booking[id]
      }else{
        empty = {}
      }
       empty[serviceid]= empty[serviceid]? empty[serviceid]:[]
       empty[serviceid][index]['status']=value
      booking[id] = empty
    }else{

    
    if (Array.isArray(booking[id])) {
      let empty = booking[id]
      empty[index] = {...empty[index],
        "status": value,

      }
      booking[id] = empty
    } else {
      let empty = []
      for (var i = 0; i < booking[id]; i++) {
        if (index === i) {
          empty.push({
            "status": value,
          })
        } else {
          empty.push({
            "status": 'Pending',
          })
        }

      }
      booking[id] = empty
    }



  }
    // debugger
    // await API.order().fetchById(order.id)
    //   .then(res => {
    //     console.log('1112>>', res.data.customer_id[0].fcmToken)
    //     setBookings(booking)
    //     setOrder({
    //       ...order,
    //       orderedServices: res.data.orderedServices,
    //       scheduledServices: booking,

    //     })
    //     setFcm(res.data.customer_id[0].fcmToken)
    //   })
    setBookings(booking)
    setSendNotfication(true)
    setSendOrderStatusNotfication(true)

    console.log('111>>', booking)
  }

  const validate = () => {
    let tempErrors = {};
    let formIsValid = true;

    if (!order.transaction_no || order.transaction_no.trim() === "") {
      formIsValid = false;
      tempErrors["transaction_no"] = "Cannot be empty";
    }


    setErrors(tempErrors);
    return formIsValid;
  }

  const handleSubmit = (e) => {

    const onSuccess = (data) => {
      setOpen(false);
      toast.success('Data succesfully updated');
      console.log('223',data)
      if (sendNotfication) {
        let description
        if (sendDateTimeNotfication) {
          description = "Appointment time has been updated."
        }
        if (sendOrderStatusNotfication) {
          description = "Appointment status has been updated"
        }
        if (sendDateTimeNotfication && sendOrderStatusNotfication) {
          description = "Appointment time & status has been updated."
        }

        API.notifications().create(
          {
            title: "Order Update for #" + order.orderID,
            description: description ? description : fcmdesc,
            type: "order",
            customerId: order.customer_id[0]
          }
        )
          .then(res => {

            setSendDateTimeNotfication(false)
            setSendOrderStatusNotfication(false)
            setSendNotfication(false)

            console.log('fcm---->', fcm)
            if (fcm.length > 1) {
              const pushNotification = {
                "registration_ids": [fcm],
                "notification": {
                  "title": "Order Update for #" + order.orderID,
                  "body": description ? description : fcmdesc,

                  "content_available": "true",
                  "sound": "default",
                  "data": {
                    id: data.id,
                    orderID:  data.orderID,
                    type: 'orderUpdate'
                  }
                }
              }
              console.log('2323>>', pushNotification)
              API.notification().sendPushNotification(pushNotification)
                .then(res => {

                  toast.success('Notfication sent succesfully');
                })

            }
          })

          API.log().create({
            moduleId: order.id,
            type: "order",
            changes: logs,
            changedBy: authUser.id,
          })
          .then(res => {
          })
      }

    }

    e.preventDefault();

    if (validate()) {

      props.update(order.id, order, onSuccess)
    }
  }

  const getCartItem = () => {
    API.service().getCart(Object.keys(props.dataOrder[10]))
      .then(res => {
  
        setServices(res.data)
      })
      .catch(err => console.log(err))
  }

  const handleDrawer = () => {
    setAnchor(!anchor)
    if(anchor){  API.log().fetchAll({
      moduleId: order.id,
      type: "order",
    })
    .then(res => {
    
      setHistory(res.data)
    })}
  }




  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      
      
       
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullScreen
      >
        <DialogTitle id="form-dialog-title" style={{ padding: "30px 30px 0px 30px" }}>Order Details</DialogTitle>


        <DialogContent style={{ padding: "30px 30px 10px 30px" }}>
       
          <Grid container spacing={3}>

            <Grid item xs={12}>
              <Grid container spacing={3}>

                <Grid item xs={3}>
                <TextField

name="orderID"
label="Order ID"
value={order.orderID}
fullWidth
disabled
onChange={handleInputChange}
{...(errors.promocode && { error: true, helperText: errors.promocode })}
/>

<br /><br />
                  <TextField

                    name="customer_email"
                    label="Customer Email"
                    value={order.customer_email}
                    fullWidth
                    disabled
                    onChange={handleInputChange}
                    {...(errors.customer_email && { error: true, helperText: errors.customer_email })}
                  />
                  <br /><br />

                  <TextField

                    name="transaction_amount"
                    label="Transaction Amount"
                    value={order.transaction_amount}
                    fullWidth
                    disabled
                    onChange={handleInputChange}
                    {...(errors.transaction_amount && { error: true, helperText: errors.transaction_amount })}
                  />

                </Grid>
                <Grid item xs={3}>
               
<TextField
                    autoFocus
                    name="transaction_no"
                    label="Transaction No"
                    value={order.transaction_no}
                    fullWidth
                    disabled
                    onChange={handleInputChange}
                    {...(errors.transaction_no && { error: true, helperText: errors.transaction_no })}
                  />
                  <br /><br />
                  <TextField

                    name="promocode"
                    label="Promocode"
                    value={order.promocode}
                    fullWidth
                    disabled
                    onChange={handleInputChange}
                    {...(errors.promocode && { error: true, helperText: errors.promocode })}
                  />

                  <br /><br />

                  <TextField

                    name="discount"
                    label="Discount"
                    value={order.discount}
                    fullWidth
                    disabled
                    onChange={handleInputChange}
                    {...(errors.discount && { error: true, helperText: errors.discount })}
                  />

                </Grid>

                <Grid item xs={3}>
                  <TextField

                     name="customer_name"
                    label="Customer Name"
                    value={order.customer_name}
                    fullWidth
                    disabled
                    onChange={handleInputChange}
                    {...(errors.customer_name && { error: true, helperText: errors.customer_name })}
                  />
                  <br /><br />

                  <TextField

                    name="customer_mobile"
                    label="Customer Mobile"
                    value={order.customer_mobile}
                    fullWidth
                    disabled
                    onChange={handleInputChange}
                    {...(errors.customer_mobile && { error: true, helperText: errors.customer_mobile })}
                  />
                  <br /><br />

                  <TextField

                    name="subTotal"
                    label="Sub Total"
                    value={order.subTotal}
                    fullWidth
                    disabled
                    onChange={handleInputChange}
                    {...(errors.subTotal && { error: true, helperText: errors.subTotal })}
                  />
                  <br /><br />

                </Grid>

                <Grid item xs={3}>

                  {/* <TextField
                    type="datetime-local"

                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="scheduleDate"
                    label="Appointment"
                    defaultValue={order.scheduleDate}
                    value={order.scheduleDate}
                    fullWidth
                    onChange={handleInputChange}
                    {...(errors.scheduleDate && { error: true, helperText: errors.scheduleDate })}
                  />
                  <br /><br />

                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Order Status</InputLabel>
                    <Select
                      name="order_status"

                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={order.order_status}
                      onChange={handleInputChange}

                    >
                      <MenuItem value={'Pending'}>Pending</MenuItem>
                      <MenuItem value={'Confirmed'}>Confirmed</MenuItem>
                      <MenuItem value={'In Progress'}>In Progress</MenuItem>
                      <MenuItem value={'Completed'}>Completed</MenuItem>
                      <MenuItem value={'Cancelled'}>Cancelled</MenuItem>
                    </Select>
                  </FormControl>
                  <br /><br /> */}
                  <TextField

                    name="note"
                    label="Notes"
                    value={order.note}
                    fullWidth
                    row={5}
                    multiline={true}
                    placeholder=" start typing..."
                    onChange={handleInputChange}
                    {...(errors.note && { error: true, helperText: errors.note })}
                  />
                </Grid>
              </Grid>


              {/* <TextField

                name="transaction_status"
                label="Transaction Status"
                value={order.transaction_status}
                fullWidth
                
                onChange={handleInputChange}
                {...(errors.transaction_status && { error: true, helperText: errors.transaction_status })}
              /> 
               <br /><br />
              */}


            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                Ordered Services/packages
              </Paper>

              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="th">#</TableCell>
                      <TableCell>Title</TableCell>
                      {/* <TableCell align="th">Quantity</TableCell> */}
                      <TableCell align="th">Type</TableCell>
                      <TableCell align="th">Price</TableCell>
                      <TableCell align="th">Booking</TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {services.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell align="th">{index + 1}</TableCell>
                        <TableCell component="th" scope="row">
                          {row.title?.en}
                        </TableCell>
                        {/* <TableCell component="th" scope="row">
                          {isFinite(order.orderedServices[row.id])?order.orderedServices[row.id]:''}
                        </TableCell> */}
                        {row.type === 'product' && <TableCell align="th">Service</TableCell>}
                        {row.type === 'item' && <TableCell align="th">Product</TableCell>}
                        {row.type === 'package' && <TableCell align="th">Package</TableCell>}
                        {row.type === 'coupon' && <TableCell align="th">Coupon</TableCell>}
                        <TableCell align="th">{row.price}</TableCell>
                        
                        {row.type !== 'package'?
                          <TableCell align="th">
                          {Array(order.orderedServices[row.id]).fill().map((e, i) => (
                            <div style={{ paddingBottom: 7 }}>

                              <Grid p={2} container spacing={3}>

                                <Grid item xs={3} >
                                  <div style={{ paddingTop: 10 }}>
                                    Booking {i + 1}
                                  </div>

                                </Grid>
                                <Grid item xs={3} >
                         
                                <FormControl fullWidth>
                                  <TextField
                                    type="date"
                                    label="Date"

                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      min: moment().format("yyyy-MM-DD"),

                                    }}
                                    name="scheduleDate"

                                    defaultValue={row.id && typeof order?.scheduledServices != "undefined" ? moment(order?.scheduledServices[row.id][0]?.date).format("yyyy-MM-DD") : ''}
                                    //value={row.id && typeof order?.scheduledServices != "undefined"?moment(order?.scheduledServices[row.id][0]?.date).format("yyyy-MM-DD"):''}

                                    onChange={(event) => {setLogs([...logs,'Date changed to '+event.target.value+' for '+row.title?.en+' Booking '+(i+1)]);onDateChange(event, row.id, i, true, row.id && typeof order?.scheduledServices != "undefined" ? order?.scheduledServices[row.id][i]?.time : '')}}
                                    {...(errors.scheduleDate && { error: true, helperText: errors.scheduleDate })}
                                  />
                                   </FormControl>
                                </Grid>
                                <Grid item xs={3} >
                                  <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">Time</InputLabel>
                                    <Select
                                      value={row.id && typeof order?.scheduledServices != "undefined" ? order?.scheduledServices[row.id][i]?.time : ''}
                                      onChange={(event) => {setLogs([...logs,'Time changed to '+event.target.value+' for '+row.title?.en+' Booking '+(i+1)]);onDateChange(event, row.id, i, false, row.id && typeof order?.scheduledServices != "undefined" ? moment(order?.scheduledServices[row.id][i]?.date).format("yyyy-MM-DD") : '')}}

                                    >
                                      {slots.map((e, i) => <MenuItem value={e.key} disabled={currentBookedSlots.includes(e.key)}>{e.label}</MenuItem>)}
                                    </Select>
                                  </FormControl>

                                </Grid>
                                <Grid item xs={3} >
                                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select
                      name="status"

                      value={row.id && typeof order?.scheduledServices != "undefined" ?order?.scheduledServices[row.id][i]?.status:''}
                      onChange={(event) => {onOrderStatusChange(event, row.id, i, row.id && typeof order?.scheduledServices != "undefined" ? order?.scheduledServices[row.id][i]?.time : '' ,row.id && typeof order?.scheduledServices != "undefined" ? moment(order?.scheduledServices[row.id][i]?.date).format("yyyy-MM-DD") : '');setLogs([...logs,'Status changed to '+event.target.value+' for '+row.title?.en+' Booking '+(i+1)])}}
               

                    >
                      <MenuItem value={'Pending'}>Pending</MenuItem>
                      <MenuItem value={'Confirmed'}>Confirmed</MenuItem>
                      <MenuItem value={'In Progress'}>In Progress</MenuItem>
                      <MenuItem value={'Completed'}>Completed</MenuItem>
                      <MenuItem value={'Cancelled'}>Cancelled</MenuItem>
                    </Select>
                  </FormControl>
                  <br /><br />
                                </Grid>

                              </Grid>

                            </div>
                          ))}
                        </TableCell>
                        :
                        <TableCell align="th">
                          {row.packageServices.map((e, i) => (
                            <>
                            <b>{e.serviceID.title?.en}</b>
                            { Array(order.orderedServices[row.id]).fill().map((et, i) => (
                          <div style={{ paddingBottom: 7 }}>

                            <Grid p={2} container spacing={3}>

                              <Grid item xs={3} >
                                <div style={{ paddingTop: 10 }}>
                                  Booking {i + 1}
                                </div>

                              </Grid>
                              <Grid item xs={3} >
                       
                              <FormControl fullWidth>
                                <TextField
                                  type="date"
                                  label="Date"

                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  inputProps={{
                                    min: moment().format("yyyy-MM-DD"),

                                  }}
                                  name="scheduleDate"

                                  defaultValue={row.id && typeof order?.scheduledServices != "undefined" && typeof order?.scheduledServices[row.id][e.serviceID.id] != "undefined" ? moment(order?.scheduledServices[row.id][e.serviceID.id][i]?.date)?.format("yyyy-MM-DD") : ''}
                              
                                  onChange={(event) => {setLogs([...logs,'Date changed to '+event.target.value+' for service '+e.serviceID.title?.en+' in package '+row.title?.en+' Booking '+(i+1)]);onDateChange(event, row.id, i, true, row.id && typeof order?.scheduledServices != "undefined" && typeof order?.scheduledServices[row.id][e.serviceID.id] != "undefined" ? order?.scheduledServices[row.id][e.serviceID.id][i]?.time : '',e.serviceID.id)}}
                                  {...(errors.scheduleDate && { error: true, helperText: errors.scheduleDate })}
                                />
                                 </FormControl>
                              </Grid>
                              <Grid item xs={3} >
                                <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Time</InputLabel>
                                  <Select
                                    value={row.id && typeof order?.scheduledServices != "undefined" && typeof order?.scheduledServices[row.id][e.serviceID.id] != "undefined" ? order?.scheduledServices[row.id][e.serviceID.id][i]?.time : ''}
                                    onChange={(event) => {setLogs([...logs,'Time changed to '+event.target.value+' for service '+e.serviceID.title?.en+' in package '+row.title?.en+' Booking '+(i+1)]);onDateChange(event, row.id, i, false, row.id && typeof order?.scheduledServices != "undefined" ? moment(order?.scheduledServices[row.id][e.serviceID.id][i]?.date).format("yyyy-MM-DD") : '',e.serviceID.id)}}

                                  >
                                    {slots.map((e, i) => <MenuItem value={e.key} disabled={currentBookedSlots.includes(e.key)}>{e.label}</MenuItem>)}
                                  </Select>
                                </FormControl>

                              </Grid>
                              <Grid item xs={3} >
                              <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    name="status"

                    value={row.id && typeof order?.scheduledServices != "undefined" && typeof order?.scheduledServices[row.id][e.serviceID.id] != "undefined" ?order?.scheduledServices[row.id][e.serviceID.id][i]?.status:''}
                    onChange={(event) => {setLogs([...logs,'Status changed to '+event.target.value+' for service '+e.serviceID.title?.en+' in package '+row.title?.en+' Booking '+(i+1)]);onOrderStatusChange(event, row.id, i, row.id && typeof order?.scheduledServices != "undefined" ? order?.scheduledServices[row.id][e.serviceID.id][i]?.time : '' ,row.id && typeof order?.scheduledServices != "undefined" ? moment(order?.scheduledServices[row.id][e.serviceID.id][i]?.date).format("yyyy-MM-DD") : '',e.serviceID.id)}}
             

                  >
                    <MenuItem value={'Pending'}>Pending</MenuItem>
                    <MenuItem value={'Confirmed'}>Confirmed</MenuItem>
                    <MenuItem value={'In Progress'}>In Progress</MenuItem>
                    <MenuItem value={'Completed'}>Completed</MenuItem>
                    <MenuItem value={'Cancelled'}>Cancelled</MenuItem>
                  </Select>
                </FormControl>
                <br /><br />
                              </Grid>

                            </Grid>

                          </div>
                        ))}
                            
                            </>
                          ))}
                       
                      </TableCell>
                        }

                      </TableRow>

                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

          </Grid>

          
          <React.Fragment >
          <Button onClick={handleDrawer}>{anchor}</Button>
          <Drawer anchor='right' open={anchor} onClose={handleDrawer}>
          <div style={{paddingLeft:10,paddingRight:10, paddingBottom:10, paddingTop:5}}>
           <Button onClick={handleDrawer} size="small" variant="outlined"><ArrowBackIcon/></Button> 
          <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="th">#</TableCell>
                      <TableCell>User</TableCell>
                      {/* <TableCell align="th">Email</TableCell> */}
                      <TableCell align="th">Date</TableCell>
                      <TableCell align="th">Changes</TableCell>
                   
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {history.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell align="th">{index + 1}</TableCell>
                        <TableCell component="th" scope="row">
                   
                        <div>{row.changedBy.firstName+' '+row.changedBy.lastName}</div>
                        <small>{row.changedBy.email}</small>
                        </TableCell>
                        {/* <TableCell component="th" scope="row">
                        {row.changedBy.email}
                        </TableCell> */}
                        <TableCell component="th" scope="row">
                        {moment(row.changedBy.createdDate).format("DD/MM/yy HH:mm a")}
                        </TableCell>
                        <TableCell component="th" scope="row">
                        
                        {row?.changes?.map((e, i) => (
                            <div style={{ paddingBottom: 5,}}>{e}
                            </div>
                          ))}
                        </TableCell>
                     

                      </TableRow>

                    ))}
                    {history.length===0 && <div style={{padding:20, alignSelf:'center', fontWeight:'bold'}}>No change logs found</div>}
                  </TableBody>
                </Table>
              </TableContainer>
          </div>
          </Drawer>
        </React.Fragment>
    
        </DialogContent>

        <DialogActions style={{ padding: 30 }}>
        <Button onClick={handleDrawer} variant="contained">Change Logs</Button>
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="secondary">
            Save
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}

export default FormDialogEditOrder;