import API from "../utils/api";

export const ACTION_TYPES = {
    BOOKING_CREATE: 'BOOKING_CREATE',
    BOOKING_UPDATE: 'BOOKING_UPDATE',
    BOOKING_DELETE: 'BOOKING_DELETE',
    BOOKING_FETCH: 'BOOKING_FETCH',
    BOOKING_FETCH_ALL: 'BOOKING_FETCH_ALL',
    BOOKING_PAGINATION: 'BOOKING_PAGINATION'
}

export const fetchAll = () => dispatch => {
    API.booking().fetchAll()
        .then(res => {
    
            dispatch({
                type: ACTION_TYPES.BOOKING_FETCH_ALL,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const Pagination = (page = 1, limit = 10, name = "") => dispatch => {
    API.booking().fetchPagination(page, Math.abs(limit), name)
        .then(res =>{
           console.log('1212',res.data)
            dispatch({
                type: ACTION_TYPES.BOOKING_PAGINATION,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const fetchById = (id, onSuccess) => dispatch => {
    API.booking().fetchById(id)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.BOOKING_FETCH,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}

export const create = (input, onSuccess) => dispatch => {
    API.booking().create(input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.BOOKING_CREATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const update = (id, input, onSuccess) => dispatch => {

    API.booking().update(id, input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.BOOKING_UPDATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const Delete = (id, onSuccess) => dispatch => {
    API.booking().delete(id)
        .then(res =>{
           
            dispatch({
                type: ACTION_TYPES.BOOKING_DELETE,
                payload: res.data.id
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}