import API from "../utils/api";

export const ACTION_TYPES = {
    SERVICE_CREATE: 'SERVICE_CREATE',
    SERVICE_UPDATE: 'SERVICE_UPDATE',
    SERVICE_DELETE: 'SERVICE_DELETE',
    SERVICE_FETCH: 'SERVICE_FETCH',
    SERVICE_FETCH_ALL: 'SERVICE_FETCH_ALL',
    SERVICE_PAGINATION: 'SERVICE_PAGINATION',
    SERVICE_FETCH_ONLY_SERV: 'SERVICE_FETCH_ONLY_SERV',
}

export const fetchAll = () => dispatch => {
    API.service().fetchAll()
        .then(res => {
      
            dispatch({
                type: ACTION_TYPES.SERVICE_FETCH_ALL,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const fetchAllServices = (param) => dispatch => {
    API.service().getFilteredAllServices(param)
        .then(res => {
      
            dispatch({
                type: ACTION_TYPES.SERVICE_FETCH_ONLY_SERV,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const Pagination = (page = 1, limit = 5, title = "") => dispatch => {
    API.service().fetchPagination(page, Math.abs(limit), title)
        .then(res =>{
         
            dispatch({
                type: ACTION_TYPES.SERVICE_PAGINATION,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const packagePagination = (page = 1, limit = 5, title = "") => dispatch => {
    API.service().fetchPagfetchPackagesPaginationination(page, Math.abs(limit), title)
        .then(res =>{
       
            dispatch({
                type: ACTION_TYPES.SERVICE_PAGINATION,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const productPagination = (page = 1, limit = 5, title = "") => dispatch => {
    API.service().fetchProductsPagination(page, Math.abs(limit), title)
        .then(res =>{
       
            dispatch({
                type: ACTION_TYPES.SERVICE_PAGINATION,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const couponPagination = (page = 1, limit = 5, title = "") => dispatch => {
    API.service().fetchCouponsPagination(page, Math.abs(limit), title)
        .then(res =>{
       
            dispatch({
                type: ACTION_TYPES.SERVICE_PAGINATION,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const fetchById = (id, onSuccess) => dispatch => {
    API.service().fetchById(id)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.SERVICE_FETCH,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}

export const create = (input, onSuccess) => dispatch => {
    API.service().create(input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.SERVICE_CREATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const update = (id, input, onSuccess) => dispatch => {

    API.service().update(id, input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.SERVICE_UPDATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const Delete = (id, onSuccess) => dispatch => {
    API.service().delete(id)
        .then(res =>{
        
            dispatch({
                type: ACTION_TYPES.SERVICE_DELETE,
                payload: res.data.id
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}