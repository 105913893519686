import { ACTION_TYPES } from "../actions/department";

const initialState = {
    departments: [],
    metaDepartment: {}
}

export const department = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.DEPARTMENT_FETCH_ALL:
            return {
                ...state,
                departments: [...action.payload]
            }
        case ACTION_TYPES.DEPARTMENT_CREATE:
            return {
                ...state,
                departments: [...state.departments, action.payload]
            }
        case ACTION_TYPES.DEPARTMENT_UPDATE:
            return {
                ...state,
                departments: state.departments.map(x => x.id === action.payload.id ? action.payload : x)
            }
        case ACTION_TYPES.DEPARTMENT_DELETE:
            return {
                ...state,
                departments:state.departments.filter(x => x.id !== action.payload)
            }
        case ACTION_TYPES.DEPARTMENT_PAGINATION:
  
            return {
                ...state,
                departments: [...action.payload.departments],
                metaDepartment: action.payload.meta
            }
        default:
            return state;
    }
}