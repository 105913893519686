import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from '@material-ui/core';
import RichTextEditor from 'react-rte';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { toast } from 'react-toastify';
import Grow from '@material-ui/core/Grow';
import API from "./../../../utils/api";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const initialFormState = {
  id: null,
  image: "",
  name: {en:'', ar:''},
  email: "",
  mobile: "",
  education: {en:'', ar:''},
  experience: {en:'', ar:''},
  categoryId: [],
  about: {en:'', ar:''},
  departments: [],
  role: 'doctor',
  educationdetails: {en:'', ar:''},
  experiencedetails: {en:'', ar:''},
  focus: {en:'', ar:''},
  languages: {en:'', ar:''},
  seoh1: { en: "", ar: "" },
  seotitle: { en: "", ar: "" },
  seometa: { en: "", ar: "" },
}

const FormDialogAddDoctor = (props) => {
  const [open, setOpen] = useState(false);
  const [doctor, setDoctor] = useState(initialFormState);
  const [errors, setErrors] = useState({})
  const [htmlDesc, setHtmlDesc] = useState(RichTextEditor.createValueFromString('', 'html'))
  const [htmlDesc11, setHtmlDesc11] = useState(RichTextEditor.createValueFromString('', 'html'))
  const [htmlDesc12, setHtmlDesc12] = useState(RichTextEditor.createValueFromString('', 'html'))
  const [htmlDesc13, setHtmlDesc13] = useState(RichTextEditor.createValueFromString('', 'html'))
  const [selectedLang, setSelectedLang] = useState('en')

  const handleClickOpen = () => {
    setErrors({});
    setDoctor(initialFormState);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }


  const handleInputChange = event => {
    const { name, value, lang } = event.target

    if (lang) {
      setDoctor({
        ...doctor,
        [name]: { ...doctor[name], [selectedLang]: value }
      });

    } else {
      setDoctor({ ...doctor, [name]: value })
    }
  }

  useEffect(() => {

    if (selectedLang === 'ar') {
      setHtmlDesc(RichTextEditor.createValueFromString(doctor.about.ar, 'html'))
      setHtmlDesc11(RichTextEditor.createValueFromString(doctor.educationdetails.ar, 'html'))
      setHtmlDesc12(RichTextEditor.createValueFromString(doctor.experiencedetails.ar, 'html'))
      setHtmlDesc13(RichTextEditor.createValueFromString(doctor.focus.ar, 'html'))
    } else {
      setHtmlDesc(RichTextEditor.createValueFromString(doctor.about.en, 'html'))
      setHtmlDesc11(RichTextEditor.createValueFromString(doctor.educationdetails.en, 'html'))
      setHtmlDesc12(RichTextEditor.createValueFromString(doctor.experiencedetails.en, 'html'))
      setHtmlDesc13(RichTextEditor.createValueFromString(doctor.focus.en, 'html'))
    }
 
}, [selectedLang]);

  const handleHtmlInputChange = (value, name) => {

    setHtmlDesc(value)
    //  setDoctor({ ...doctor, about: value.toString('html') })
    setDoctor({
      ...doctor,
      [name]: { ...doctor[name], [selectedLang]: value.toString('html') }
    });
  }
  const handleHtmlInputChange11 = (value, name) => {

    setHtmlDesc11(value)
    //  setDoctor({ ...doctor, about: value.toString('html') })
    setDoctor({
      ...doctor,
      [name]: { ...doctor[name], [selectedLang]: value.toString('html') }
    });
  }
  const handleHtmlInputChange12 = (value, name) => {

    setHtmlDesc12(value)
    //  setDoctor({ ...doctor, about: value.toString('html') })
    setDoctor({
      ...doctor,
      [name]: { ...doctor[name], [selectedLang]: value.toString('html') }
    });
  }
  const handleHtmlInputChange13 = (value, name) => {

    setHtmlDesc13(value)
    //  setDoctor({ ...doctor, about: value.toString('html') })
    setDoctor({
      ...doctor,
      [name]: { ...doctor[name], [selectedLang]: value.toString('html') }
    });
  }


  const handleInputChangeForFileType = event => {

    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());
    API.utility().upload(formdata)
      .then(res => {
        setDoctor({ ...doctor, image: res.data.data.path })
      })

  }

  const validate = () => {
    let tempErrors = {};
    let formIsValid = true;

    if (!doctor.image || doctor.image.trim() === "") {
      formIsValid = false;
      tempErrors["image"] = "Cannot be empty";
    }



    if (!doctor.categoryId) {
      formIsValid = false;
      tempErrors["categoryId"] = "Cannot be empty";
    }


    setErrors(tempErrors);
    return formIsValid;
  }

  const handleSubmit = (e) => {
    const onSuccess = () => {
      props.refresh()
      setOpen(false);
      toast.success('Data succesfully updated');
    }
    e.preventDefault();

    if (validate()) {
      props.create(doctor, onSuccess)
    }
  }

  const handleChange = (event) => {
    setDoctor({ ...doctor, categoryId: event.target.value })

  };

  const handleChangeForDepartments = (event) => {
    setDoctor({ ...doctor, departments: event.target.value })

  };

  const handleBranchChangeForServices = (event) => {
    setDoctor({ ...doctor, branch: event.target.value })

  };

  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen} >
        <AddCircleIcon style={{ fontSize: "40px" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'lg'}
      >
        <DialogTitle id="form-dialog-title" style={{ padding: "30px 30px 0px 30px" }}>Add doctor</DialogTitle>

        <DialogContent style={{ padding: "30px 30px 10px 30px" }}>


          <div className="row">
            <div className="col-lg-12 col-12 mb-4">
              <Button variant="contained" component="label">
                Upload Picture
                <input
                  type="file"
                  name="file"
                  label="Image"
                  onChange={handleInputChangeForFileType}
                  {...(errors.file && { error: true, helperText: errors.file })}
                  hidden
                />
              </Button>
              <img src={process.env.REACT_APP_IMG_URL + doctor.image} height="100" style={{ marginTop: "-30px", float: "right" }} />
            </div>
          </div>

          <div className="row border p-2">

            <div className="col-lg-12 col-12 mb-4">
              <FormControl className="float-end">
                <InputLabel id="demo-simple-select-label">Language</InputLabel>
                <Select
                  value={selectedLang}
                  onChange={(e) => setSelectedLang(e.target.value)}
                >
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="ar">Arabic</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="col-lg-12 col-12 mb-4">
              <TextField

                name="name"
                label="Name"
                value={doctor?.name[selectedLang]}
                inputProps={{ 'lang': selectedLang }}
                fullWidth
                onChange={handleInputChange}
                {...(errors.name && { error: true, helperText: errors.name })}
              />
            </div>

            <div className="col-lg-12 col-12 mb-4">
              <TextField

                name="education"
                label="Education"

                value={doctor?.education[selectedLang]}
                inputProps={{ 'lang': selectedLang }}
                fullWidth
                onChange={handleInputChange}

                {...(errors.education && { error: true, helperText: errors.education })}
              />
            </div>

            <div className="col-lg-12 col-12 mb-4">
              <TextField

                name="experience"
                label="Experience"
                value={doctor?.experience[selectedLang]}
                inputProps={{ 'lang': selectedLang }}
                fullWidth
                onChange={handleInputChange}

                {...(errors.experience && { error: true, helperText: errors.experience })}
              />
            </div>

            <div className="col-lg-12 col-12 mb-4">
              <TextField

                name="languages"
                label="languages"
                value={doctor?.languages?.en ? doctor?.languages[selectedLang] : ''}
                inputProps={{ 'lang': selectedLang }}
                fullWidth
                onChange={handleInputChange}

                {...(errors.languages && { error: true, helperText: errors.languages })}
              />
            </div>

            <div className="col-lg-12 col-12 mb-4">
              <p>About</p>
              <RichTextEditor
                value={htmlDesc}
                onChange={(val) => handleHtmlInputChange(val, 'about')}
              />
            </div>
            <div className="col-lg-12 col-12 mb-4">
              <p>Education Details</p>
              <RichTextEditor
                value={htmlDesc11}
                onChange={(val) => handleHtmlInputChange11(val, 'educationdetails')}
              />
            </div>
            <div className="col-lg-12 col-12 mb-4">
              <p>Experience Details</p>
              <RichTextEditor
                value={htmlDesc12}
                onChange={(val) => handleHtmlInputChange12(val, 'experiencedetails')}
              />
            </div>
            <div className="col-lg-12 col-12 mb-4">
              <p>Focus area</p>
              <RichTextEditor
                value={htmlDesc13}
                onChange={(val) => handleHtmlInputChange13(val, 'focus')}
              />
            </div>
            <div className="col-lg-6 col-12 mb-4">
              <TextField
                name="seoh1"
                label="SEO h1"
      
                value={doctor?.seoh1[selectedLang]}
                inputProps={{ 'lang': selectedLang }}
                fullWidth
                onChange={handleInputChange}
                {...(errors.seoh1 && { error: true, helperText: errors.seoh1 })}
              />
      
            </div>
      
            <div className="col-lg-6 col-12 mb-4">
              <TextField
                name="seotitle"
                label="SEO Title"
      
                value={doctor?.seotitle[selectedLang]}
                inputProps={{ 'lang': selectedLang }}
                fullWidth
                onChange={handleInputChange}
                {...(errors.seotitle && { error: true, helperText: errors.seotitle })}
              />
      
            </div>
      
            <div className="col-lg-12 col-12 mb-4">
              <TextField
                name="seometa"
                label="SEO Meta"
      
                value={doctor?.seometa[selectedLang]}
                inputProps={{ 'lang': selectedLang }}
                fullWidth
                onChange={handleInputChange}
                {...(errors.seometa && { error: true, helperText: errors.seometa })}
              />
      
            </div>
          </div>




          <TextField

            name="email"
            label="Email"
            value={doctor.email}
            fullWidth
            onChange={handleInputChange}

            {...(errors.email && { error: true, helperText: errors.email })}
          />
          <br /><br />

          <TextField

            name="mobile"
            label="Mobile"
            value={doctor.mobile}
            fullWidth
            onChange={handleInputChange}

            {...(errors.mobile && { error: true, helperText: errors.mobile })}
          />
          <br /><br />



          <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={doctor.categoryId}
              onChange={handleChange}
              multiple
            >
              {props.categories.map(item => (
                <MenuItem value={item.id}>{item.title?.en}</MenuItem>
              ))}

            </Select>
          </FormControl>


          <br /><br />

          <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Departments</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={doctor.departments}
              onChange={handleChangeForDepartments}
              multiple
            >
              {props.departments.map(item => (
                <MenuItem value={item.id}>{item.title.en}</MenuItem>
              ))}

            </Select>
          </FormControl>


          <br /><br />



          <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Choose Centre/Branch</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={doctor.branch}
              onChange={handleBranchChangeForServices}

            >
              {props.branches.map(item => (
                <MenuItem value={item.id}>{item.name}</MenuItem>
              ))}

            </Select>
          </FormControl>

          <br /><br />


        </DialogContent>

        <DialogActions style={{ padding: 30 }}>
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="secondary">
            Save
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}

export default FormDialogAddDoctor;