import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { toast } from 'react-toastify';
import Grow from '@material-ui/core/Grow';
import API from "../../../utils/api";
import { Grid, IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from '@material-ui/core';
import RichTextEditor from 'react-rte';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const initialFormState = { 
	id: null, 
  name: "",
  mobile: "",
  email: "",
  message: "",
}

const FormDialogAddContact = (props) => {
  const [open, setOpen] = useState(false);
  const [contact, setContact] = useState(initialFormState);
  const [errors, setErrors ] = useState({})
  const [question, setQuestion ] = useState('')
  const [answer, setAnswer ] = useState('')

  const handleClickOpen = () => {
      setErrors({});
      setContact(initialFormState);
      setOpen(true);
  }

  const handleClose = () => {
      setOpen(false);
  }

  const handleInputChange = event => {
		const { name, value } = event.target
    setContact({ ...contact, [name]: value })
  }
  const handleInputChangeForFileType = event => {
 
    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());
    API.utility().upload(formdata)
    .then(res =>{
      setContact({ ...contact, icon: res.data.data.path })
    })

  }

  const handleInputChangeForGallary = event => {
 
    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());
    API.utility().upload(formdata)
    .then(res =>{
      setContact({ ...contact, gallary: [...contact.gallary,res.data.data.path] })

      console.log(contact)
    })

  }
  const deleteFromGallary = (i) => {

    let gal = contact.gallary

    gal.splice(i, 1)
 
    setContact({ ...contact, gallary: gal })

      console.log(contact)

  }

  const handleChangeForThumbnail = (img) => {

    console.log()

    setContact({ ...contact, image: img })

  };

  const handleChangeForDoctor = (event) => {
    setContact({ ...contact, doctor: event.target.value })

  };

  const validate = () => {
      let tempErrors = {};
      let formIsValid = true;

      if(!contact.title || contact.title.trim() ===  ""){
        formIsValid = false;
        tempErrors["title"] = "Cannot be empty";
      }

      if(!contact.description || contact.description.trim() ===  ""){
        formIsValid = false;
        tempErrors["description"] = "Cannot be empty";
      }

      setErrors(tempErrors);
      return formIsValid;
  }

  const handleSubmit = (e) => {
      const onSuccess = () => {
          props.refresh()
          setOpen(false);
          toast.success('Data succesfully updated');
      }
      e.preventDefault();

      if(validate()){
        props.create(contact, onSuccess)
      }
  }
  const addQna = () => {


    if(question && answer){
      let obj = {
        question : question,
        answer: answer
      }
      setContact({ ...contact, qna: [...contact?.qna, obj] })
      console.log(contact)
      setQuestion('')
      setAnswer('')
    }
}

const deleteFromQna = (i) => {

  let qna = contact?.qna

  qna.splice(i, 1)

  setContact({ ...contact, qna: qna })

    console.log(contact)

}


  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen} >
          <AddCircleIcon style={{ fontSize: "40px" }} />
      </IconButton>
      <Dialog  
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
      >
            <DialogTitle id="form-dialog-title" style={{padding: "30px 30px 0px 30px"}}>Add contact</DialogTitle>

            <DialogContent style={{padding: "30px 30px 10px 30px"}}>
           

                <TextField
                  autoFocus
                  name="title"
                  label="Title"
                  value={contact.title}
                  fullWidth
                  onChange={handleInputChange}
                  {...(errors.title && { error: true, helperText: errors.title })}
                />
<br /><br />
<br /><br />  
                <TextField
                  autoFocus
                  name="description"
                  label="Description"
                  value={contact.description}
                  fullWidth
                  onChange={handleInputChange}
                  multiline={true}
                  rows={2}
                  {...(errors.description && { error: true, helperText: errors.description })}
                />
                

<br /><br />

<br /><br />

          <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Choose Doctor</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={contact.doctor}
              onChange={handleChangeForDoctor}
           
            >
              {props.doctors.filter((e)=>e.role==='doctor').map(item => (
                <MenuItem value={item.id}>{item.name}</MenuItem>
              ))}

            </Select>
          </FormControl>

          <br /><br />
          <br /><br />
<h2>Add QNA</h2>
          <TextField
                  
                  name="question"
                  label="Question"
                  value={question}
                  fullWidth
              
                  onChange={(e)=>setQuestion(e.target.value)}
                  multiline={true}
                  rows={1}
                
                />
                

<br /><br />
<TextField
                  
                  name="answer"
                  label="Answer"
                  value={answer}
                  fullWidth
                  onChange={(e)=>setAnswer(e.target.value)}
                  multiline={true}
                  rows={1}
           
                />
                

<br /><br />


<button className="btn btn-secondary" onClick={addQna}>
+ Add QNA
</button>

             
<br /><br />    

<Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          
            <>
            <table class="table">
  <thead>
    <tr>
      <th scope="col">Question</th>
      <th scope="col">Answer</th>
      <th scope="col">#</th>
    </tr>
  </thead>
  <tbody>
  {contact?.qna?.map((data, i) => (
    <tr>
      <th>{data.question}</th>
      <td>{data.answer}</td>
      <td>  <a onClick={()=>deleteFromQna(i)}>
                  <DeleteIcon/>
                  </a></td>
    </tr>
       ))}
  </tbody>
</table>
           
            </>
       
          </Grid>
      
      
            </DialogContent>

            <DialogActions style={{padding: 30}}>
                <Button variant="contained" onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button variant="contained" onClick={handleSubmit} color="secondary">
                    Save
                </Button>
            </DialogActions>

      </Dialog>
    </div>
  );
}

export default FormDialogAddContact;