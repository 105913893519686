import { ACTION_TYPES } from "../actions/category";

const initialState = {
    categories: [],
    metaCategory: {}
}

export const category = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.CATEGORY_FETCH_ALL:
            return {
                ...state,
                categories: [...action.payload]
            }
        case ACTION_TYPES.CATEGORY_CREATE:
            return {
                ...state,
                categories: [...state.categories, action.payload]
            }
        case ACTION_TYPES.CATEGORY_UPDATE:
            return {
                ...state,
                categories: state.categories.map(x => x.id === action.payload.id ? action.payload : x)
            }
        case ACTION_TYPES.CATEGORY_DELETE:
            return {
                ...state,
                categories:state.categories.filter(x => x.id !== action.payload)
            }
        case ACTION_TYPES.CATEGORY_PAGINATION:
     
            return {
                ...state,
                categories: [...action.payload.categories],
                metaCategory: action.payload.meta
            }
        default:
            return state;
    }
}