import API from "../utils/api";

export const ACTION_TYPES = {
    CONTACT_CREATE: 'CONTACT_CREATE',
    CONTACT_UPDATE: 'CONTACT_UPDATE',
    CONTACT_DELETE: 'CONTACT_DELETE',
    CONTACT_FETCH: 'CONTACT_FETCH',
    CONTACT_FETCH_ALL: 'CONTACT_FETCH_ALL',
    CONTACT_PAGINATION: 'CONTACT_PAGINATION'
}

export const fetchAll = () => dispatch => {
    API.contact().fetchAll()
        .then(res => {
   
            dispatch({
                type: ACTION_TYPES.CONTACT_FETCH_ALL,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const Pagination = (page = 1, limit = 5, name = "", mobile="", email="") => dispatch => {
    API.contact().fetchPagination(page, Math.abs(limit), name, mobile, email)
        .then(res =>{
         console.log('chet152662',res.data)
            dispatch({
                type: ACTION_TYPES.CONTACT_PAGINATION,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const fetchById = (id, onSuccess) => dispatch => {
    API.contact().fetchById(id)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.CONTACT_FETCH,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}

export const create = (input, onSuccess) => dispatch => {
    API.contact().create(input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.CONTACT_CREATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const update = (id, input, onSuccess) => dispatch => {
   
    API.contact().update(id, input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.CONTACT_UPDATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const Delete = (id, onSuccess) => dispatch => {
    API.contact().delete(id)
        .then(res =>{
         
            dispatch({
                type: ACTION_TYPES.CONTACT_DELETE,
                payload: res.data.id
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}