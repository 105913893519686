import API from "../utils/api";

export const ACTION_TYPES = {
    RESULT_CREATE: 'RESULT_CREATE',
    RESULT_UPDATE: 'RESULT_UPDATE',
    RESULT_DELETE: 'RESULT_DELETE',
    RESULT_FETCH: 'RESULT_FETCH',
    RESULT_FETCH_ALL: 'RESULT_FETCH_ALL',
    RESULT_PAGINATION: 'RESULT_PAGINATION'
}

export const fetchAll = () => dispatch => {
    API.result().fetchAll()
        .then(res => {
      
            dispatch({
                type: ACTION_TYPES.RESULT_FETCH_ALL,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const Pagination = (page = 1, limit = 5, title = "") => dispatch => {
    API.result().fetchPagination(page, Math.abs(limit), title)
        .then(res =>{
         
            dispatch({
                type: ACTION_TYPES.RESULT_PAGINATION,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const fetchById = (id, onSuccess) => dispatch => {
    API.result().fetchById(id)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.RESULT_FETCH,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}

export const create = (input, onSuccess) => dispatch => {
    API.result().create(input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.RESULT_CREATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const update = (id, input, onSuccess) => dispatch => {
   
    API.result().update(id, input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.RESULT_UPDATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const Delete = (id, onSuccess) => dispatch => {
    API.result().delete(id)
        .then(res =>{
         
            dispatch({
                type: ACTION_TYPES.RESULT_DELETE,
                payload: res.data.id
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}