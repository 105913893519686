import React from "react";
import { Paper, Grid, withStyles } from '@material-ui/core';
import PageTitle from "../../components/PageTitle/PageTitle";
import './style.css';
import OrderTable from "./table/OrderTable"
import { useLocation } from 'react-router-dom'

const styles = theme => ({
    paper: {
        padding: theme.spacing(0),
    },
    paperTable: {
        padding: theme.spacing(0),
    }
})

const Order = ({ classes, ...props }) => {
    const location = useLocation();

    return (
        <React.Fragment>
            {location.pathname==="/admin/order" && <PageTitle title="Orders" />}
            <Grid container spacing={4}>
                <Grid item xs={12} >
                    <Paper className={classes.paper}>
                    <OrderTable CurrentPathname={location.pathname}/>
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}


export default (withStyles(styles)(Order));