import { ACTION_TYPES } from "../actions/service";

const initialState = {
    services: [],
    metaService: {},
    servicesonly:[]
}

export const service = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.SERVICE_FETCH_ALL:
            return {
                ...state,
                services: [...action.payload]
            }
        case ACTION_TYPES.SERVICE_CREATE:
            return {
                ...state,
                services: [...state.services, action.payload]
            }
        case ACTION_TYPES.SERVICE_UPDATE:
            return {
                ...state,
                services: state.services.map(x => x.id === action.payload.id ? action.payload : x)
            }
        case ACTION_TYPES.SERVICE_DELETE:
            return {
                ...state,
                services:state.services.filter(x => x.id !== action.payload)
            }
        case ACTION_TYPES.SERVICE_PAGINATION:
    
            return {
                ...state,
                services: [...action.payload.services],
                metaService: action.payload.meta
            }
            case ACTION_TYPES.SERVICE_FETCH_ONLY_SERV:
                return {
                    ...state,
                    servicesonly: [...action.payload]
                }
        default:
            return state;
    }
}