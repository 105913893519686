import API from "../utils/api";

export const ACTION_TYPES = {
    OFFER_CREATE: 'OFFER_CREATE',
    OFFER_UPDATE: 'OFFER_UPDATE',
    OFFER_DELETE: 'OFFER_DELETE',
    OFFER_FETCH: 'OFFER_FETCH',
    OFFER_FETCH_ALL: 'OFFER_FETCH_ALL',
    OFFER_PAGINATION: 'OFFER_PAGINATION'
}

export const fetchAll = () => dispatch => {
    API.offer().fetchAll()
        .then(res => {
       
            dispatch({
                type: ACTION_TYPES.OFFER_FETCH_ALL,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const Pagination = (page = 1, limit = 5, title = "") => dispatch => {

    API.offer().fetchPagination(page, Math.abs(limit), title)
        .then(res =>{
           console.log('122',res)
            dispatch({
                type: ACTION_TYPES.OFFER_PAGINATION,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const fetchById = (id, onSuccess) => dispatch => {
    API.offer().fetchById(id)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.OFFER_FETCH,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}

export const create = (input, onSuccess) => dispatch => {
    API.offer().create(input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.OFFER_CREATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const update = (id, input, onSuccess) => dispatch => {
  
    API.offer().update(id, input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.OFFER_UPDATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const Delete = (id, onSuccess) => dispatch => {
    API.offer().delete(id)
        .then(res =>{
           
            dispatch({
                type: ACTION_TYPES.OFFER_DELETE,
                payload: res.data.id
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}