import { ACTION_TYPES } from "../actions/branche";

const initialState = {
    branches: [],
    metaBranche: {}
}

export const branche = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.BRANCHE_FETCH_ALL:
            return {
                ...state,
                branches: [...action.payload]
            }
        case ACTION_TYPES.BRANCHE_CREATE:
            return {
                ...state,
                branches: [...state.branches, action.payload]
            }
        case ACTION_TYPES.BRANCHE_UPDATE:
            return {
                ...state,
                branches: state.branches.map(x => x.id === action.payload.id ? action.payload : x)
            }
        case ACTION_TYPES.BRANCHE_DELETE:
            return {
                ...state,
                branches:state.branches.filter(x => x.id !== action.payload)
            }
        case ACTION_TYPES.BRANCHE_PAGINATION:
      
            return {
                ...state,
                branches: [...action.payload.branches],
                metaCategory: action.payload.meta
            }
        default:
            return state;
    }
}