import { ACTION_TYPES } from "../actions/result";

const initialState = {
    results: [],
    metaResult: {}
}

export const result = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.RESULT_FETCH_ALL:
            return {
                ...state,
                results: [...action.payload]
            }
        case ACTION_TYPES.RESULT_CREATE:
            return {
                ...state,
                results: [...state.results, action.payload]
            }
        case ACTION_TYPES.RESULT_UPDATE:
            return {
                ...state,
                results: state.results.map(x => x.id === action.payload.id ? action.payload : x)
            }
        case ACTION_TYPES.RESULT_DELETE:
            return {
                ...state,
                results:state.results.filter(x => x.id !== action.payload)
            }
        case ACTION_TYPES.RESULT_PAGINATION:
  
            return {
                ...state,
                results: [...action.payload.results],
                metaResult: action.payload.meta
            }
        default:
            return state;
    }
}