import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { toast } from 'react-toastify';
import Grow from '@material-ui/core/Grow';
import API from "../../../utils/api";
import Picker from 'emoji-picker-react';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import Grid from '@material-ui/core/Grid';
import { IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const initialFormState = {
  id: null,
  title: "",
  description: "",
  image: "",
  redirectionType: "home",
  redirectionObj: {},
  sendToAll: false,
  users: [],
}

const FormDialogAddFcm = (props) => {
  const [open, setOpen] = useState(false);
  const [fcm, setFcm] = useState(initialFormState);
  const [errors, setErrors] = useState({})
  const [redirectionData, setRedirectionData] = useState([])
  const [showTitleEmoji, setShowTitleEmoji] = useState(false)
  const [showBodyEmoji, setShowBodyEmoji] = useState(false)

  const handleClickOpen = () => {
    setErrors({});
    setFcm(initialFormState);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleInputChange = event => {
    const { name, value } = event.target
    setFcm({ ...fcm, [name]: value })
  }

  const handleInputChangeForFileType = event => {

    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());
    API.utility().upload(formdata)
      .then(res => {
        setFcm({ ...fcm, image:  res.data.data.path })
      })

  }

  const validate = () => {
    let tempErrors = {};
    let formIsValid = true;


    if (!fcm.title || fcm.title.trim() === "") {
      formIsValid = false;
      tempErrors["title"] = "Cannot be empty";
    }

    setErrors(tempErrors);
    return formIsValid;
  }

  const handleSubmit = (e) => {
    const onSuccess = () => {
      props.refresh()
      setOpen(false);
      console.log(fcm.users)
      const pushNotification = {
         "registration_ids": fcm.users,//['cv-ubahJcEBGktgcg3OaDP:APA91bEkJSU0mAO_rJf-AhNNzhDvx_1_gtgvGDTKhStNvUPmzXh9kvrj6nN1-26mzqveRIum8kRUHNFhEYfdTsvFVB9dCB7SGWhTYWgIkjWnkgPzGUSO_XtGk1xV4AeL6Ckt2N_fpjLA', 'dOb2eiWrTXKnWcS-FbKYqB:APA91bGaEDnXe2yRtSd827IhDSdMcinWo-2GqLsQN7Mnp7JCS8Nc8GJFWS5siAhjvxeF0RKuAmaHhvnc3bGoCz6Lhavi82JNS6731XmZ-VBqLVs92TatkIoocWbhSN7HBt-9Qh8tPmjQ'],
        "notification": {
            "title": fcm.title,
            "body": fcm.description,
            "image": process.env.REACT_APP_IMG_URL+"view-media/1633319277678/dr-yousra_dermatologist.jpeg",
            "content_available": "true",
            "sound": "default"
        }
    }
    API.notification().sendPushNotification(pushNotification)
        .then(res => {
     
            toast.success('Notfication sent succesfully');
        })
     
    }
    e.preventDefault();

    if (validate()) {
      props.create(fcm, onSuccess)
    }
  }

  const handleChangeForRedirectionType = (event) => {
    setFcm({ ...fcm, redirectionType: event.target.value });
    console.log(props);
    
    switch (event.target.value) {
      case 'service':
        setRedirectionData(props.services)
        break;

      case 'package':
        setRedirectionData(props.services)
        break;

      case 'offer':
        setRedirectionData(props.offers)
        break;

      case 'coupon':
        setRedirectionData(props.coupons)
        break;

    }

  };

  const handleChangeForRedirectionData = (event) => {

    setFcm({ ...fcm, redirectionObj: event.target.value });
  }

  const handleChangeForUsers = (event) => {
    setFcm({ ...fcm, users: event.target.value })

  };

  const handleChangeForAllReceipeints = (event) => {

    setFcm({ ...fcm, sendToAll: event.target.checked })

  };

  const onEmojiClick = (event, emojiObject) => {
    console.log(emojiObject)
    setFcm({ ...fcm, title: fcm.title.concat(emojiObject.emoji) })
  };

  const onBodyEmojiClick = (event, emojiObject) => {
    console.log(emojiObject)
    setFcm({ ...fcm, description: fcm.description.concat(emojiObject.emoji) })
  };



  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen} >
        <AddCircleIcon style={{ fontSize: "40px" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle id="form-dialog-title" style={{ padding: "30px 30px 0px 30px" }}>Add fcm</DialogTitle>

        <DialogContent style={{ padding: "30px 30px 10px 30px" }}>
          <Grid container spacing={1} alignItems="flex-end">

            <Grid fullWidth xs={11} item>
              <TextField
                autoFocus
                name="title"
                label="Notification Title"
                value={fcm.title}
                fullWidth
                onChange={handleInputChange}
                {...(errors.title && { error: true, helperText: errors.title })}
              />
            </Grid>
            <Grid xs={1} item>
              {showTitleEmoji &&
                <div style={{ position: 'absolute', right: 0, zIndex: 10, marginTop: 30 }}>

                  <Picker onEmojiClick={onEmojiClick}
                    style={{ marginTop: 30 }}
                  />
                </div>
              }
              <EmojiEmotionsIcon
                onClick={() => {
                  setShowTitleEmoji(!showTitleEmoji);
                  setShowBodyEmoji(false)
                }}
              />
            </Grid>
          </Grid>

          <br /><br />


          <Grid container spacing={1} alignItems="flex-end">

            <Grid fullWidth xs={11} item>
              <TextField

                name="description"
                label="Notification Message"
                value={fcm.description}
                fullWidth
                onChange={handleInputChange}
                multiline={true}
                rows={2}
                {...(errors.description && { error: true, helperText: errors.description })}
              />
            </Grid>
            <Grid xs={1} item>
              {showBodyEmoji &&
                <div style={{ position: 'absolute', right: 0, zIndex: 10, marginTop: 30 }}>

                  <Picker onEmojiClick={onBodyEmojiClick}
                    style={{ marginTop: 30 }}
                  />
                </div>
              }
              <EmojiEmotionsIcon
                onClick={() => {
                  setShowBodyEmoji(!showBodyEmoji);
                  setShowTitleEmoji(false)
                }}
              />
            </Grid>
          </Grid>


          <br /><br />
          <TextField

            type="file"
            name="file"
            label="Image"
            variant="outlined"

            onChange={handleInputChangeForFileType}
            {...(errors.file && { error: true, helperText: errors.file })}
          />

          <img src={process.env.REACT_APP_IMG_URL+fcm.image} height="100" style={{ marginTop: "-30px", float: "right" }} />

          <br /><br />
          <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Redirection Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={fcm.redirectionType}
              onChange={handleChangeForRedirectionType}

            >

              <MenuItem value="service">Service</MenuItem>
              <MenuItem value="package">Package</MenuItem>
              <MenuItem value="offer">Offer</MenuItem>
              <MenuItem value="coupon">Coupon</MenuItem>


            </Select>
          </FormControl>

          <br /><br />


          {fcm.redirectionType.length > 1 && <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Choose Redirection {fcm.redirectionType}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={fcm.redirectionObj}
              onChange={handleChangeForRedirectionData}
            >
              {redirectionData.map(item => (
                <MenuItem value={item}>{item.title}</MenuItem>
              ))}

            </Select>
          </FormControl>
          }
          <br /><br />


          <FormControlLabel control={
            <Checkbox
              checked={fcm.sendToAll}
              onChange={handleChangeForAllReceipeints}
              inputProps={{ 'aria-label': 'controlled' }}

            />
          } label="Send to all users" />
          <br /><br />
          <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Choose Receipeints</InputLabel>
            <Select
              disabled={fcm.sendToAll}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={fcm.users}
              onChange={handleChangeForUsers}
              multiple
            >
              {props.users.map(item => (
                <MenuItem key={item.id} value={item.fcmToken}>{item.firstName} ({item.email})</MenuItem>
              ))}

            </Select>
          </FormControl>

          <br /><br />
        </DialogContent>

        <DialogActions style={{ padding: 30 }}>
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="secondary">
            Save
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}

export default FormDialogAddFcm;