import API from "../utils/api";

export const ACTION_TYPES = {
    GIFTCARDORDER_CREATE: 'GIFTCARDORDER_CREATE',
    GIFTCARDORDER_UPDATE: 'GIFTCARDORDER_UPDATE',
    GIFTCARDORDER_DELETE: 'GIFTCARDORDER_DELETE',
    GIFTCARDORDER_FETCH: 'GIFTCARDORDER_FETCH',
    GIFTCARDORDER_FETCH_ALL: 'GIFTCARDORDER_FETCH_ALL',
    GIFTCARDORDER_PAGINATION: 'GIFTCARDORDER_PAGINATION'
}

export const fetchAll = () => dispatch => {
    API.giftcardorder().fetchAll()
        .then(res => {
       
            dispatch({
                type: ACTION_TYPES.GIFTCARDORDER_FETCH_ALL,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const Pagination = (page = 1, limit = 5, title = "") => dispatch => {

    API.giftcardorder().fetchPagination(page, Math.abs(limit), title)
        .then(res =>{
           console.log('122',res)
            dispatch({
                type: ACTION_TYPES.GIFTCARDORDER_PAGINATION,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const fetchById = (id, onSuccess) => dispatch => {
    API.giftcardorder().fetchById(id)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.GIFTCARDORDER_FETCH,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}

export const create = (input, onSuccess) => dispatch => {
    API.giftcardorder().create(input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.GIFTCARDORDER_CREATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const update = (id, input, onSuccess) => dispatch => {
  
    API.giftcardorder().update(id, input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.GIFTCARDORDER_UPDATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const Delete = (id, onSuccess) => dispatch => {
    API.giftcardorder().delete(id)
        .then(res =>{
           
            dispatch({
                type: ACTION_TYPES.GIFTCARDORDER_DELETE,
                payload: res.data.id
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}