import API from "../utils/api";

export const ACTION_TYPES = {
    FCM_CREATE: 'FCM_CREATE',
    FCM_UPDATE: 'FCM_UPDATE',
    FCM_DELETE: 'FCM_DELETE',
    FCM_FETCH: 'FCM_FETCH',
    FCM_FETCH_ALL: 'FCM_FETCH_ALL',
    FCM_PAGINATION: 'FCM_PAGINATION'
}

export const fetchAll = () => dispatch => {
    API.fcm().fetchAll()
        .then(res => {
    
            dispatch({
                type: ACTION_TYPES.FCM_FETCH_ALL,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const Pagination = (page = 1, limit = 5, title = "") => dispatch => {
    API.fcm().fetchPagination(page, Math.abs(limit), title)
        .then(res =>{
           
            dispatch({
                type: ACTION_TYPES.FCM_PAGINATION,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const fetchById = (id, onSuccess) => dispatch => {
    API.fcm().fetchById(id)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.FCM_FETCH,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}

export const create = (input, onSuccess) => dispatch => {
    API.fcm().create(input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.FCM_CREATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const update = (id, input, onSuccess) => dispatch => {

    API.fcm().update(id, input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.FCM_UPDATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const Delete = (id, onSuccess) => dispatch => {
    API.fcm().delete(id)
        .then(res =>{
           
            dispatch({
                type: ACTION_TYPES.FCM_DELETE,
                payload: res.data.id
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}