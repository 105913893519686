import API from "../utils/api";

export const ACTION_TYPES = {
    EVENT_CREATE: 'EVENT_CREATE',
    EVENT_UPDATE: 'EVENT_UPDATE',
    EVENT_DELETE: 'EVENT_DELETE',
    EVENT_FETCH: 'EVENT_FETCH',
    EVENT_FETCH_ALL: 'EVENT_FETCH_ALL',
    EVENT_PAGINATION: 'EVENT_PAGINATION'
}

export const fetchAll = () => dispatch => {
    API.event().fetchAll()
        .then(res => {
      
            dispatch({
                type: ACTION_TYPES.EVENT_FETCH_ALL,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const Pagination = (page = 1, limit = 5, title = "") => dispatch => {
    API.event().fetchPagination(page, Math.abs(limit), title)
        .then(res =>{
         
            dispatch({
                type: ACTION_TYPES.EVENT_PAGINATION,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const fetchById = (id, onSuccess) => dispatch => {
    API.event().fetchById(id)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.EVENT_FETCH,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}

export const create = (input, onSuccess) => dispatch => {
    API.event().create(input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.EVENT_CREATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const update = (id, input, onSuccess) => dispatch => {
   
    API.event().update(id, input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.EVENT_UPDATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const Delete = (id, onSuccess) => dispatch => {
    API.event().delete(id)
        .then(res =>{
         
            dispatch({
                type: ACTION_TYPES.EVENT_DELETE,
                payload: res.data.id
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}