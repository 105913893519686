import { ACTION_TYPES } from "../actions/feedback";

const initialState = {
    feedbacks: [],
    metaUser: {},
    meta: {}
}

export const feedback = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.FEEDBACK_FETCH_ALL:
            return {
                ...state,
                feedbacks: action.payload.result,
                meta: action.payload.meta
            }
        case ACTION_TYPES.FEEDBACK_CREATE:
            return {
                ...state,
                feedbacks: [...state.feedbacks, action.payload]
            }
        case ACTION_TYPES.FEEDBACK_UPDATE:
            return {
                ...state,
                feedbacks: state.feedbacks.map(x => x.id === action.payload.id ? action.payload : x)
            }
        case ACTION_TYPES.FEEDBACK_DELETE:
            return {
                ...state,
                feedbacks: state.feedbacks.filter(x => x.id !== action.payload)
            }
        case ACTION_TYPES.FEEDBACK_PAGINATION:

            return {
                ...state,
                feedbacks: [...action.payload.feedbacks],
                metaUser: action.payload.meta
            }
        default:
            return state;
    }
}