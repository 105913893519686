import { ACTION_TYPES } from "../actions/promocode";

const initialState = {
    promocodes: [],
    metaPromocode: {}
}

export const promocode = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.PROMOCODE_FETCH_ALL:
            return {
                ...state,
                promocodes: [...action.payload]
            }
        case ACTION_TYPES.PROMOCODE_CREATE:
            return {
                ...state,
                promocodes: [...state.promocodes, action.payload]
            }
        case ACTION_TYPES.PROMOCODE_UPDATE:
            return {
                ...state,
                promocodes: state.promocodes.map(x => x.id === action.payload.id ? action.payload : x)
            }
        case ACTION_TYPES.PROMOCODE_DELETE:
            return {
                ...state,
                promocodes:state.promocodes.filter(x => x.id !== action.payload)
            }
        case ACTION_TYPES.PROMOCODE_PAGINATION:
      
            return {
                ...state,
                promocodes: [...action.payload.promocodes],
                metaPromocode: action.payload.meta
            }
        default:
            return state;
    }
}