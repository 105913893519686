import React, { useState , PropTypes} from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { toast } from 'react-toastify';
import Grow from '@material-ui/core/Grow';
import API from "../../../utils/api";
import { CheckBox } from "@material-ui/icons";

import { Grid, IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from '@material-ui/core';
import RichTextEditor from 'react-rte';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const initialFormState = { 
	id: null, 
  name: "",
  mobile: "",
  email: "",
  message: "",

}

const FormDialogEditContact = (props) => {
 
  const [open, setOpen] = useState(false);
  const [contact, setContact] = useState(initialFormState);
  const [errors, setErrors ] = useState({})


  const handleClickOpen = () => {
      setErrors({});
      setContact({
        id : props.dataContact[0],
        name : props.dataContact[1],
        email : props.dataContact[2],
        mobile: props.dataContact[3],
        message: props.dataContact[4],
      
      })
      setOpen(true);
  }

  const handleClose = () => {
      setOpen(false);
  }

  const handleInputChange = event => {
		const { name, value } = event.target
    setContact({ ...contact, [name]: value })
  }
  
  const validate = () => {
      let tempErrors = {};
      let formIsValid = true;

      if(!contact.title || contact.title.trim() ===  ""){
        formIsValid = false;
        tempErrors["title"] = "Cannot be empty";
      }

      if(!contact.description || contact.description.trim() ===  ""){
        formIsValid = false;
        tempErrors["description"] = "Cannot be empty";
      }
  

      setErrors(tempErrors);
      return formIsValid;
  }

  const handleSubmit = (e) => {

      const onSuccess = () => {
          setOpen(false);
          toast.success('Data succesfully updated');
      }
      e.preventDefault();

      if(validate()){
     
        props.update(contact.id, contact, onSuccess)
      }
  }


  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen}>
          <EditIcon />
      </IconButton>
      <Dialog  
     
     fullWidth
     maxWidth={'lg'}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        
      >
            <DialogTitle id="form-dialog-title" style={{padding: "30px 30px 0px 30px"}}>Message</DialogTitle>

            <DialogContent style={{padding: "30px 30px 10px 30px"}}>
           

        

                <TextField
                  autoFocus
                  name="name"
                  label="Name"
                  disabled
                  value={contact.name}
                  fullWidth
                  onChange={handleInputChange}
                  {...(errors.name && { error: true, helperText: errors.name })}
                />
<br /><br />
 
                <TextField
                  autoFocus
                  name="mobile"
                  label="Mobile"
                  value={contact.mobile}
                  fullWidth
                  onChange={handleInputChange}
                  multiline={true}
                  rows={2}
                  disabled
                  {...(errors.mobile && { error: true, helperText: errors.mobile })}
                />

<br /><br />
 
 <TextField
   autoFocus
   name="email"
   label="Email"
   disabled
   value={contact.email}
   fullWidth
   onChange={handleInputChange}
   multiline={true}
   rows={2}
   {...(errors.email && { error: true, helperText: errors.email })}
 />
                

<br /><br />

<TextField
   autoFocus
   name="message"
   label="Message"
   value={contact.message}
   fullWidth
   disabled
   onChange={handleInputChange}
   multiline={true}
   rows={2}
   {...(errors.message && { error: true, helperText: errors.message })}
 />
                

<br /><br />

      
            </DialogContent>

            <DialogActions style={{padding: 30}}>
                <Button variant="contained" onClick={handleClose} color="primary">
                    Cancel
                </Button>
                {/* <Button variant="contained" onClick={handleSubmit} color="secondary">
                    Save
                </Button> */}
            </DialogActions>

      </Dialog>
    </div>
  );
}

export default FormDialogEditContact;