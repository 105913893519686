import { ACTION_TYPES } from "../actions/event";

const initialState = {
    events: [],
    metaEvent: {}
}

export const event = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.EVENT_FETCH_ALL:
            return {
                ...state,
                events: [...action.payload]
            }
        case ACTION_TYPES.EVENT_CREATE:
            return {
                ...state,
                events: [...state.events, action.payload]
            }
        case ACTION_TYPES.EVENT_UPDATE:
            return {
                ...state,
                events: state.events.map(x => x.id === action.payload.id ? action.payload : x)
            }
        case ACTION_TYPES.EVENT_DELETE:
            return {
                ...state,
                events:state.events.filter(x => x.id !== action.payload)
            }
        case ACTION_TYPES.EVENT_PAGINATION:
    
            return {
                ...state,
                events: [...action.payload.events],
                metaEvent: action.payload.meta
            }
        default:
            return state;
    }
}