import API from "../utils/api";

export const ACTION_TYPES = {
    BRANCHE_CREATE: 'BRANCHE_CREATE',
    BRANCHE_UPDATE: 'BRANCHE_UPDATE',
    BRANCHE_DELETE: 'BRANCHE_DELETE',
    BRANCHE_FETCH: 'BRANCHE_FETCH',
    BRANCHE_FETCH_ALL: 'BRANCHE_FETCH_ALL',
    BRANCHE_PAGINATION: 'BRANCHE_PAGINATION'
}

export const fetchAll = () => dispatch => {
 
    API.branche().fetchAll()
        .then(res => {

            dispatch({
                type: ACTION_TYPES.BRANCHE_FETCH_ALL,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const Pagination = (page = 1, limit = 5, title = "") => dispatch => {
    API.branche().fetchPagination(page, Math.abs(limit), title)
        .then(res =>{
           
            dispatch({
                type: ACTION_TYPES.BRANCHE_PAGINATION,
                payload: res.data
            })
        })
        .catch(err => console.log(err))
}

export const fetchById = (id, onSuccess) => dispatch => {
    API.branche().fetchById(id)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.BRANCHE_FETCH,
                payload: res.data
            })
            onSuccess(res.data)
        })
        .catch(err => console.log(err))
}

export const create = (input, onSuccess) => dispatch => {
    API.branche().create(input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.BRANCHE_CREATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const update = (id, input, onSuccess) => dispatch => {

    API.branche().update(id, input)
        .then(res =>{
            dispatch({
                type: ACTION_TYPES.BRANCHE_UPDATE,
                payload: res.data
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}

export const Delete = (id, onSuccess) => dispatch => {
    API.branche().delete(id)
        .then(res =>{
           
            dispatch({
                type: ACTION_TYPES.BRANCHE_DELETE,
                payload: res.data.id
            })
            onSuccess()
        })
        .catch(err => console.log(err))
}