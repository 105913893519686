import { ACTION_TYPES } from "../actions/offer";

const initialState = {
    offers: [],
    metaOffer: {}
}

export const offer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.OFFER_FETCH_ALL:
            return {
                ...state,
                offers: [...action.payload]
            }
        case ACTION_TYPES.OFFER_CREATE:
            return {
                ...state,
                offers: [...state.offers, action.payload]
            }
        case ACTION_TYPES.OFFER_UPDATE:
            return {
                ...state,
                offers: state.offers.map(x => x.id === action.payload.id ? action.payload : x)
            }
        case ACTION_TYPES.OFFER_DELETE:
            return {
                ...state,
                offers:state.offers.filter(x => x.id !== action.payload)
            }
        case ACTION_TYPES.OFFER_PAGINATION:

            return {
                ...state,
                offers: [...action.payload.offers],
                metaOffer: action.payload.meta
            }
        default:
            return state;
    }
}