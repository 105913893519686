import { ACTION_TYPES } from "../actions/coupon";

const initialState = {
    coupons: [],
    metaCoupon: {}
}

export const coupon = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.COUPON_FETCH_ALL:
            return {
                ...state,
                coupons: [...action.payload]
            }
        case ACTION_TYPES.COUPON_CREATE:
            return {
                ...state,
                coupons: [...state.coupons, action.payload]
            }
        case ACTION_TYPES.COUPON_UPDATE:
            return {
                ...state,
                coupons: state.coupons.map(x => x.id === action.payload.id ? action.payload : x)
            }
        case ACTION_TYPES.COUPON_DELETE:
            return {
                ...state,
                coupons:state.coupons.filter(x => x.id !== action.payload)
            }
        case ACTION_TYPES.COUPON_PAGINATION:
      
            return {
                ...state,
                coupons: [...action.payload.coupons],
                metaCoupon: action.payload.meta
            }
        default:
            return state;
    }
}