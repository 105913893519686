import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { toast } from 'react-toastify';
import Grow from '@material-ui/core/Grow';
import API from "./../../../utils/api";
import { Grid, IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from '@material-ui/core';
import RichTextEditor from 'react-rte';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const initialFormState = {
  id: null,
  title: "",
  description: "",
  image: "https://picsum.photos/200",
  gallary: [],
  doctor: '',
  qna: [],
  icon: '',
  services:[],
  seoh1: { en: "", ar: "" },
  seotitle: { en: "", ar: "" },
  seometa: { en: "", ar: "" },
}

const FormDialogAddDepartment = (props) => {
  const [open, setOpen] = useState(false);
  const [department, setDepartment] = useState(initialFormState);
  const [errors, setErrors] = useState({})
  const [question, setQuestion] = useState({ en: '', ar: '' })
  const [answer, setAnswer] = useState({ en: '', ar: '' })
  const [htmlDesc, setHtmlDesc] = useState(RichTextEditor.createValueFromString('', 'html'))
  const [selectedLang, setSelectedLang] = useState('en')
  const [selectedQna, setSelectedQna] = useState(null)

  const handleClickOpen = () => {
    setErrors({});
    setDepartment(initialFormState);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleInputChange = event => {
    const { name, value, lang } = event.target

    if (lang) {
      setDepartment({
        ...department,
        [name]: { ...department[name], [selectedLang]: value }
      });

    } else {
      setDepartment({ ...department, [name]: value })
    }
  }
  const handleChangeServices = (event) => {
    setDepartment({ ...department, services: event.target.value })

  };

  useEffect(() => {

    if (department.description.ar) {
      if (selectedLang === 'ar') {
        setHtmlDesc(RichTextEditor.createValueFromString(department.description.ar, 'html'))
      } else {
        setHtmlDesc(RichTextEditor.createValueFromString(department.description.en, 'html'))
      }
    }
  }, [selectedLang]);

  const handleHtmlInputChange = value => {

    setHtmlDesc(value)

    setDepartment({
      ...department,
      ['description']: { ...department['description'], [selectedLang]: value.toString('html') }
    });
  }

  const handleInputChangeForFileType = event => {

    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());
    API.utility().upload(formdata)
      .then(res => {
        setDepartment({ ...department, icon: res.data.data.path })
      })

  }

  const handleInputChangeForGallary = event => {

    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());
    API.utility().upload(formdata)
      .then(res => {
        setDepartment({ ...department, gallary: [...department.gallary, res.data.data.path] })

        console.log(department)
      })

  }
  const deleteFromGallary = (i) => {

    let gal = department.gallary

    gal.splice(i, 1)

    setDepartment({ ...department, gallary: gal })

    console.log(department)

  }

  const handleChangeForThumbnail = (img) => {

    console.log()

    setDepartment({ ...department, image: img })

  };

  const handleChangeForDoctor = (event) => {
    setDepartment({ ...department, doctor: event.target.value })

  };

  const validate = () => {
    let tempErrors = {};
    let formIsValid = true;

    // if (!department.title || department.title.trim() === "") {
    //   formIsValid = false;
    //   tempErrors["title"] = "Cannot be empty";
    // }

    // if (!department.description || department.description.trim() === "") {
    //   formIsValid = false;
    //   tempErrors["description"] = "Cannot be empty";
    // }

    setErrors(tempErrors);
    return formIsValid;
  }

  const handleSubmit = (e) => {
    const onSuccess = () => {
      props.refresh()
      setOpen(false);
      toast.success('Data succesfully updated');
    }
    e.preventDefault();

    if (validate()) {
      props.create(department, onSuccess)
    }
  }


  const addQna = () => {
    if (question && answer) {
    let obj = {
      question: question,
      answer: answer
    }

    if(selectedQna !=null && isFinite(selectedQna)){
   
      let qna = department?.qna
      qna[selectedQna] = obj
      setDepartment({ ...department, qna: qna })
      setSelectedQna(null)
    }else{

      setDepartment({ ...department, qna: [...department?.qna, obj] })
    }
      setQuestion({ en: '', ar: '' })
      setAnswer({ en: '', ar: '' })
     
    }
    
  }

  const deleteFromQna = (i) => {

    let qna = department?.qna

    qna.splice(i, 1)

    setDepartment({ ...department, qna: qna })

    console.log(department)

  }
  const editFromQna = (i) => {
    setSelectedQna(i)
    let qna = department?.qna

    console.log(qna[i])
    setQuestion(qna[i].question)
      setAnswer(qna[i].answer)
      document.getElementById('qna').scrollIntoView()
  }



  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen} >
        <AddCircleIcon style={{ fontSize: "40px" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'lg'}
      >
        <DialogTitle id="form-dialog-title" style={{ padding: "30px 30px 0px 30px" }}>Add department</DialogTitle>
        <DialogContent style={{ padding: "30px 30px 10px 30px" }}>
          <Button variant="contained" component="label">
            Upload Icon
            <input
              type="file"
              name="file"
              label="Image"
              onChange={handleInputChangeForFileType}
              {...(errors.file && { error: true, helperText: errors.file })}
              hidden
            />
          </Button>
          <img src={process.env.REACT_APP_IMG_URL + department.icon} height="100" style={{ marginTop: "-30px", marginLeft: '20px' }} />
          <br /><br />

          <Button variant="contained" component="label">
            Upload Picture in Gallary
            <input
              type="file"
              name="file"
              label="Image"
              onChange={handleInputChangeForGallary}
              {...(errors.file && { error: true, helperText: errors.file })}
              hidden
            />
          </Button>
          <br /><br />

          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            {department.gallary.map((img, i) => (
              <Grid item xs={2} sm={3} md={3} key={i}>

                <img key={i} src={process.env.REACT_APP_IMG_URL + img} height="140" style={{ marginRight: "10px" }} />
                <FormControlLabel control={
                  <Checkbox
                    checked={department.image === img}
                    onChange={() => handleChangeForThumbnail(img)}
                  />
                } label="Set Thumb" />

                <a onClick={() => deleteFromGallary(i)}>
                  <DeleteIcon />
                </a>

              </Grid>

            ))}
          </Grid>


          <div className="row border p-2 mb-4 mt-3">

            <div className="col-lg-12 col-12 mb-4">
              <FormControl className="float-end">
                <InputLabel id="demo-simple-select-label">Language</InputLabel>
                <Select
                  value={selectedLang}
                  onChange={(e) => setSelectedLang(e.target.value)}
                >
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="ar">Arabic</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="col-lg-12 col-12 mb-4">
              <TextField

                name="title"
                label="Title"
                value={department?.title[selectedLang]}
                inputProps={{ 'lang': selectedLang }}
                fullWidth
                onChange={handleInputChange}
                {...(errors.title && { error: true, helperText: errors.title })}
              />
            </div>

            <div className="col-lg-12 col-12 mb-4">
              <p>Description</p>
              <RichTextEditor
                value={htmlDesc}
                onChange={handleHtmlInputChange}
              />

            </div>

            <div className="col-lg-6 col-12 mb-4">
              <TextField
                name="seoh1"
                label="SEO h1"
      
                value={department?.seoh1[selectedLang]}
                inputProps={{ 'lang': selectedLang }}
                fullWidth
                onChange={handleInputChange}
                {...(errors.seoh1 && { error: true, helperText: errors.seoh1 })}
              />
      
            </div>
      
            <div className="col-lg-6 col-12 mb-4">
              <TextField
                name="seotitle"
                label="SEO Title"
      
                value={department?.seotitle[selectedLang]}
                inputProps={{ 'lang': selectedLang }}
                fullWidth
                onChange={handleInputChange}
                {...(errors.seotitle && { error: true, helperText: errors.seotitle })}
              />
      
            </div>
      
            <div className="col-lg-12 col-12 mb-4">
              <TextField
                name="seometa"
                label="SEO Meta"
      
                value={department?.seometa[selectedLang]}
                inputProps={{ 'lang': selectedLang }}
                fullWidth
                onChange={handleInputChange}
                {...(errors.seometa && { error: true, helperText: errors.seometa })}
              />
      
            </div>
          </div>

          <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Treatments</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={department.services}
              onChange={handleChangeServices}
                multiple
            >
              {props.services.map(item => (
                <MenuItem value={item.id}>{item.title?.en}</MenuItem>
              ))}

            </Select>
          </FormControl>
          <br /><br />

          <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Choose Doctor</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={department.doctor}
              onChange={handleChangeForDoctor}

            >
              {props.doctors.filter((e) => e.role === 'doctor').map(item => (
                <MenuItem value={item.id}>{item.name?.en}</MenuItem>
              ))}

            </Select>
          </FormControl>

          <br /><br />

          <div className="card mt-4" id="qna">
            <div className="card-body">
              <strong>QNA Section</strong>
              <TextField

                name="question"
                label="Question en"
                value={question.en}
                fullWidth

                onChange={(e) => setQuestion({ ...question, en: e.target.value })}
                multiline={true}
                rows={1}

              />
              <TextField

                name="question"
                label="Question Ar"
                value={question.ar}
                fullWidth

                onChange={(e) => setQuestion({ ...question, ar: e.target.value })}
                multiline={true}
                rows={1}

              />


              <br /><br />
              <TextField

                name="answer"
                label="Answer En"
                value={answer.en}
                fullWidth
                onChange={(e) => setAnswer({ ...answer, en: e.target.value })}
                multiline={true}
                rows={1}

              />
              <TextField

                name="answer"
                label="Answer Ar"
                value={answer.ar}
                fullWidth
                onChange={(e) => setAnswer({ ...answer, ar: e.target.value })}
                multiline={true}
                rows={1}

              />


              <br /><br />


              <button className="btn btn-secondary float-end" onClick={addQna}>
                 Save QNA
              </button>
            </div>
          </div>



          <br /><br />

          {department?.qna?.length > 0 && <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>

            <>
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Question</th>
                    <th scope="col">Answer</th>
                    <th scope="col">#</th>
                  </tr>
                </thead>
                <tbody>
                  {department?.qna?.map((data, i) => (
                    <tr>
                      <th>{data.question[selectedLang]}</th>
                      <td>{data.answer[selectedLang]}</td>
                      <td>
                      <a onClick={() => editFromQna(i)}>
                        <EditIcon />
                      </a>
                      </td>
                      <td>  
                      
                      <a onClick={() => deleteFromQna(i)}>
                        <DeleteIcon />
                      </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

            </>

          </Grid>}


        </DialogContent>

        <DialogActions style={{ padding: 30 }}>
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="secondary">
            Save
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}

export default FormDialogAddDepartment;