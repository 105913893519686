import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { toast } from 'react-toastify';
import Grow from '@material-ui/core/Grow';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const initialFormState = {
  id: null,
  promoCode: "",
  discount: "",
  description: "",

}

const FormDialogAddPromocode = (props) => {
  const [open, setOpen] = useState(false);
  const [promocode, setPromocode] = useState(initialFormState);
  const [errors, setErrors] = useState({})

  const handleClickOpen = () => {
    setErrors({});
    setPromocode(initialFormState);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleInputChange = event => {
    const { name, value } = event.target
    setPromocode({ ...promocode, [name]: value })
  }

  const validate = () => {
    let tempErrors = {};
    let formIsValid = true;


    if (!promocode.promoCode || promocode.promoCode.trim() === "") {
      formIsValid = false;
      tempErrors["promoCode"] = "Cannot be empty";
    }

    if (!promocode.discount) {
      formIsValid = false;
      tempErrors["discount"] = "Cannot be empty";
    }
    setErrors(tempErrors);
    return formIsValid;
  }

  const handleSubmit = (e) => {
    const onSuccess = () => {
      props.refresh()
      setOpen(false);
      toast.success('Data succesfully updated');
    }
    e.preventDefault();

    if (validate()) {
      props.create(promocode, onSuccess)
    }
  }

  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen} >
        <AddCircleIcon style={{ fontSize: "40px" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle id="form-dialog-title" style={{ padding: "30px 30px 0px 30px" }}>Add promocode</DialogTitle>

        <DialogContent style={{ padding: "30px 30px 10px 30px" }}>

          <TextField
            autoFocus
            name="promoCode"
            label="Promo Code"
            value={promocode.promoCode}
            fullWidth
            onChange={handleInputChange}
            {...(errors.promoCode && { error: true, helperText: errors.promoCode })}
          />
          <br /><br />

          <TextField
            autoFocus
            name="discount"
            label="Discount"
            value={promocode.discount}
            type="number"
            fullWidth
            onChange={handleInputChange}
            {...(errors.discount && { error: true, helperText: errors.discount })}
          />
          <br /><br />

          <TextField
            autoFocus
            name="description"
            label="Description"
            value={promocode.description}
            fullWidth
            onChange={handleInputChange}
            multiline={true}
            rows={2}
            {...(errors.description && { error: true, helperText: errors.description })}
          />


        </DialogContent>

        <DialogActions style={{ padding: 30 }}>
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="secondary">
            Save
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}

export default FormDialogAddPromocode;