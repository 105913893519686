import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";


import { toast } from 'react-toastify';
import Grow from '@material-ui/core/Grow';
import API from "../../../utils/api";
import { IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const initialFormState = { 
  id: null,
  image: "",
  title: {en:"", ar: ""},
  description: {en:"", ar: ""},
  location: {en:"", ar: ""},
  rating: ""

}

const FormDialogAddEvent = (props) => {
  const [open, setOpen] = useState(false);
  const [event, setEvent] = useState(initialFormState);
  const [errors, setErrors ] = useState({})
  const [selectedLang, setSelectedLang] = useState('en')

  const handleClickOpen = () => {
      setErrors({});
      setEvent(initialFormState);
      setOpen(true);
  }

  const handleClose = () => {
      setOpen(false);
  }

  // const handleInputChange = e => {
	// 	const { name, value } = e.target
  //   setEvent({ ...event, [name]: value })
  // }

  const handleInputChange = e => {
    const { name, value, lang } = e.target

    if (lang) {
      setEvent({
        ...event,
        [name]: { ...event[name], [lang]: value }
      });

    } else {
      setEvent({ ...event, [name]: value })
    }
  }
  
  const validate = () => {
      let tempErrors = {};
      let formIsValid = true;

   

      setErrors(tempErrors);
      return formIsValid;
  }
  const handleInputChangeForFileType = ev => {

    var formdata = new FormData();
    formdata.append("file", ev.target.files[0]);
    formdata.append("documentId", Date.now());
    API.utility().upload(formdata)
      .then(res => {
        setEvent({ ...event, image: res.data.data.path })
      })

  }
  const handleSubmit = (e) => {
      const onSuccess = () => {
          props.refresh()
          setOpen(false);
          toast.success('Data succesfully updated');
      }
      e.preventDefault();

      if(validate()){
        props.create(event, onSuccess)
      }
  }

  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen} >
          <AddCircleIcon style={{ fontSize: "40px" }} />
      </IconButton>
      <Dialog  
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
            <DialogTitle id="form-dialog-title" style={{padding: "30px 30px 0px 30px"}}>Add event</DialogTitle>

           
            <DialogContent style={{ padding: "30px 30px 10px 30px" }}>
        <Button variant="contained" component="label">
            Upload Icon
            <input
              type="file"
              name="file"
              label="Image"
              onChange={handleInputChangeForFileType}
              {...(errors.file && { error: true, helperText: errors.file })}
              hidden
            />
          </Button>

          <img src={process.env.REACT_APP_IMG_URL + event.image} height="100" style={{ marginTop: "-30px", float: "right" }} />


          <div className="row">
  <div className="col-lg-12 col-12 mb-4">
      <FormControl className="float-end">
        <InputLabel id="demo-simple-select-label">Language</InputLabel>
        <Select
          value={selectedLang}
          onChange={(e) => setSelectedLang(e.target.value)}
        >
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="ar">Arabic</MenuItem>
        </Select>
      </FormControl>
    </div>
    

    <div className="col-lg-12 col-12 mb-4">
      <TextField

        name="title"
        label="Title"
        value={event?.title[selectedLang]}
        inputProps={{ 'lang': selectedLang }}
        fullWidth
        onChange={handleInputChange}
       {...(errors.title && { error: true, helperText: errors.title })}
      />


    </div>
    <div className="col-lg-12 col-12 mb-4">
     
      <TextField

        name="description"
        label="Description"
        value={event?.description[selectedLang]}
        inputProps={{ 'lang': selectedLang }}
        fullWidth
        onChange={handleInputChange}
        multiline={true}
        rows={3}
        {...(errors.description && { error: true, helperText: errors.description })}
      />

      

    </div>

    <div className="col-lg-12 col-12 mb-4">
     
      <TextField

        name="location"
        label="Location"
        value={event?.location[selectedLang]}
        inputProps={{ 'lang': selectedLang }}
        fullWidth
        onChange={handleInputChange}
        multiline={true}
        rows={3}
        {...(errors.location && { error: true, helperText: errors.location })}
      />

      
<br /><br />

          <TextField
            type="number"
            name="rating"
            label="Rating"
            value={event.rating}
            fullWidth
            onChange={handleInputChange}
            {...(errors.rating && { error: true, helperText: errors.rating })}
          />
    </div>
    
  </div>
     

        
            

        </DialogContent>

            <DialogActions style={{padding: 30}}>
                <Button variant="contained" onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button variant="contained" onClick={handleSubmit} color="secondary">
                    Save
                </Button>
            </DialogActions>

      </Dialog>
    </div>
  );
}

export default FormDialogAddEvent;