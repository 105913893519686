import { ACTION_TYPES } from "../actions/review";

const initialState = {
    reviews: [],
    metaReview: {}
}

export const review = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.REVIEW_FETCH_ALL:
            return {
                ...state,
                reviews: [...action.payload]
            }
        case ACTION_TYPES.REVIEW_CREATE:
            return {
                ...state,
                reviews: [...state.reviews, action.payload]
            }
        case ACTION_TYPES.REVIEW_UPDATE:
            return {
                ...state,
                reviews: state.reviews.map(x => x.id === action.payload.id ? action.payload : x)
            }
        case ACTION_TYPES.REVIEW_DELETE:
            return {
                ...state,
                reviews:state.reviews.filter(x => x.id !== action.payload)
            }
        case ACTION_TYPES.REVIEW_PAGINATION:
      
            return {
                ...state,
                reviews: [...action.payload.reviews],
                metaReview: action.payload.meta
            }
        default:
            return state;
    }
}