import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import { toast } from 'react-toastify';
import Grow from '@material-ui/core/Grow';
import API from "../../../utils/api";
import moment from 'moment';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const initialFormState = {
  id: null,

}

const FormDialogEditDoctorbooking = (props) => {
  const [open, setOpen] = useState(false);
  const [doctorbooking, setDoctorbooking] = useState(initialFormState);
  const [errors, setErrors] = useState({})

  const handleClickOpen = () => {
    setErrors({});
    setDoctorbooking({
      id: props.dataDoctorbooking[0],
      doctor: props.dataDoctorbooking[1].map(a => a._id),
      services: props.dataDoctorbooking[2],
      type: props.dataDoctorbooking[3],
      customerFirstname: props.dataDoctorbooking[4],
      customerLastname: props.dataDoctorbooking[5],
      customerEmail: props.dataDoctorbooking[6],
      customerPhone: props.dataDoctorbooking[7],
      bookingDate: props.dataDoctorbooking[8],
      bookingTime: props.dataDoctorbooking[9],
      status: props.dataDoctorbooking[10],

    })
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleInputChange = event => {
    const { name, value } = event.target
    console.log(name,value)
    setDoctorbooking({ ...doctorbooking, [name]: value })
  }

  const validate = () => {
    let tempErrors = {};
    let formIsValid = true;

    setErrors(tempErrors);
    return formIsValid;
  }

  const handleSubmit = (e) => {

    const onSuccess = (data) => {
   
      setOpen(false);
      toast.success('Data succesfully updated', data.customer.fcmToken);
      const pushNotification = {
        "registration_ids": [data.customer.fcmToken],//
       "notification": {
           "title": 'Appointment Update: '+data.doctor.name,
           "body": 'Status: '+doctorbooking.status+' | Appointment Date: '+moment(doctorbooking.bookingDate).format("DD MMM yy LT"),
          // "image": process.env.REACT_APP_IMG_URL+"view-media/1633319277678/dr-yousra_dermatologist.jpeg",
           "content_available": "true",
           "sound": "default"
       }
   }
   API.notification().sendPushNotification(pushNotification)
       .then(res => {
    
           toast.success('Notfication sent succesfully');
       })
    
   }
    
    e.preventDefault();

    if (validate()) {
     
      props.update(doctorbooking.id, {
        doctor: doctorbooking.doctorId,
        bookingDate: doctorbooking.bookingDate,
        bookingTime: doctorbooking.bookingTime,
        status: doctorbooking.status
      }, onSuccess)
    }
  }
  const handleChangeForServices = (event) => {

    setDoctorbooking({ ...doctorbooking, doctor: event.target.value })

  };
 

  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle id="form-dialog-title" style={{ padding: "30px 30px 0px 30px" }}>Update Doctor Booking</DialogTitle>

        <DialogContent style={{ padding: "30px 30px 10px 30px" }}>

        <TextField
  disabled={true}
  label="Customer Name"
  value={doctorbooking.customerFirstname+' '+doctorbooking.customerLastname}
  fullWidth

/>
<br /><br />
<TextField
  disabled={true}
  label="Customer Email"
  value={doctorbooking.customerEmail}
  fullWidth

/>
<br /><br />
<TextField
  disabled={true}
  label="Customer Email"
  value={doctorbooking.customerPhone}
  fullWidth

/>
<br /><br />


<FormControl
  fullWidth={true}
>
  <InputLabel id="demo-simple-select-label">Doctor</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={doctorbooking.doctor}
    onChange={handleChangeForServices}
    multiple
  >
    {props.doctors.map(item => (
      <MenuItem value={item.id}>{item.name.en}</MenuItem>
    ))}

  </Select>
</FormControl>
<br /><br />



<Grid container spacing={3}>

<Grid item xs={12}>
<TextField
  type="date"
  disabled={!doctorbooking.bookingDate}
  InputLabelProps={{
    shrink: true,
  }}
  name="bookingDate"
  label="Booking Date"
//  defaultValue={doctorbooking.bookingDate}
  value={moment(doctorbooking.bookingDate).format("YYYY-MM-DD")
}
  fullWidth
  onChange={handleInputChange}
  {...(errors.bookingDate && { error: true, helperText: errors.bookingDate })}
/>
</Grid>

<Grid item xs={12}>
<TextField
  type="time"
  disabled={!doctorbooking.bookingTime}
  InputLabelProps={{
    shrink: true,
  }}
  name="bookingTime"
  label="Booking Time"
//  defaultValue={doctorbooking.bookingDate}
  value={doctorbooking.bookingTime
}
  fullWidth
  onChange={handleInputChange}
  {...(errors.bookingTime && { error: true, helperText: errors.bookingTime })}
/>
</Grid>


</Grid>

<br /><br />
<FormControl  fullWidth>
                      <InputLabel id="demo-simple-select-label">Order Status {doctorbooking.status}</InputLabel>
                      <Select
                       name="status"
                       
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={doctorbooking.status}
                        onChange={handleInputChange}
                       
                      >
                        <MenuItem value={'pending'}>Pending</MenuItem>
                        <MenuItem value={'Confirmed'}>Confirmed</MenuItem>
                        <MenuItem value={'In Progress'}>In Progress</MenuItem>                        
                        <MenuItem value={'Completed'}>Completed</MenuItem>
                        <MenuItem value={'Cancelled'}>Cancelled</MenuItem>
                      </Select>
                    </FormControl>
              

</DialogContent>


        <DialogActions style={{ padding: 30 }}>
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="secondary">
            Save
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}

export default FormDialogEditDoctorbooking;