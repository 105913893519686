import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from '@material-ui/core';
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import EditIcon from '@material-ui/icons/Edit';
import { toast } from 'react-toastify';
import Grow from '@material-ui/core/Grow';
import API from "../../../utils/api";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const initialFormState = {
  id: null,
  orderno: 0,
  name: "",
  email: "",
  mobile: "",
  message: "",
  sender: "",
  amount: 0,
  expiry: 0,
  giftcard: {},
  transactionNumber: "",
  note: "",
  redeemptionStatus: 'pending',
}

const FormDialogEditGiftcardorder = (props) => {
  const [open, setOpen] = useState(false);
  const [giftcardorder, setGiftcardorder] = useState(initialFormState);
  const [errors, setErrors] = useState({})
  const [selectedLang, setSelectedLang] = useState('en')

  const handleClickOpen = () => {
    setErrors({});
    setGiftcardorder({
      id: props.dataGiftcardorder[0],
      orderno: props.dataGiftcardorder[1],
      name: props.dataGiftcardorder[2],
      email: props.dataGiftcardorder[3],
      mobile: props.dataGiftcardorder[4],
      message: props.dataGiftcardorder[5],
      sender: props.dataGiftcardorder[6],
      amount: props.dataGiftcardorder[7],
      expiry: props.dataGiftcardorder[8],
      giftcard: props.dataGiftcardorder[9],
      transactionNumber: props.dataGiftcardorder[10],
      note: props.dataGiftcardorder[11],
      redeemptionStatus: props.dataGiftcardorder[12],
    })
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleInputChange = event => {
    const { name, value, lang } = event.target

    if (lang) {
      setGiftcardorder({
        ...giftcardorder,
        [name]: { ...giftcardorder[name], [lang]: value }
      });

    } else {
      setGiftcardorder({ ...giftcardorder, [name]: value })
    }
  }

  const handleInputChangeForFileType = event => {

    var formdata = new FormData();
    formdata.append("file", event.target.files[0]);
    formdata.append("documentId", Date.now());
    API.utility().upload(formdata)
      .then(res => {
        setGiftcardorder({ ...giftcardorder, image: res.data.data.path })
      })

  }

  const validate = () => {
    let tempErrors = {};
    let formIsValid = true;

   

    setErrors(tempErrors);
    return formIsValid;
  }

  const handleSubmit = (e) => {

    const onSuccess = () => {
      setOpen(false);
      toast.success('Data succesfully updated');
    }
    e.preventDefault();

    if (validate()) {

      props.update(giftcardorder.id, giftcardorder, onSuccess)
    }
  }

  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle id="form-dialog-title" style={{ padding: "30px 30px 0px 30px" }}>Gift card order details</DialogTitle>

        <DialogContent style={{ padding: "30px 30px 10px 30px" }}>


          <div className="col-lg-12 col-12 mb-4">
            <div className="row">
            
            <div className="col-lg-6 col-12 mb-4">
                <TextField
                  name="name"
                  label="Order Number"
                  value={giftcardorder.orderno}
                  fullWidth
                  disabled
                />
                
              </div>

              <div className="col-lg-6 col-12 mb-4">
                <TextField
                  name="name"
                  label="Receiver's Name"
                  value={giftcardorder.name}
                  fullWidth
                  disabled
                />
                
              </div>
              <div className="col-lg-6 col-12 mb-4">
               
                 <TextField
                  name="email"
                  label="Receiver's Email"
                  value={giftcardorder.email}
                  fullWidth
                  disabled
                />
              </div>
              <div className="col-lg-6 col-12 mb-4">
               
                 <TextField
                  name="email"
                  label="Receiver's Mobile"
                  value={giftcardorder.mobile}
                  fullWidth
                  disabled
                />
              </div>
              <div className="col-lg-6 col-12 mb-4">
               
               <TextField
                name="email"
                label="Amount"
                value={giftcardorder.amount}
                fullWidth
                disabled
              />
            </div>
            <div className="col-lg-6 col-12 mb-4">
               
               <TextField
                name="email"
                label="Transaction Number"
                value={giftcardorder.transactionNumber}
                fullWidth
                disabled
              />
            </div>
            <div className="col-lg-6 col-12 mb-4">
               
               <TextField
                name="email"
                label="Expiry"
                value={giftcardorder.expiry}
                fullWidth
                disabled
              />
            </div>
            <div className="col-lg-6 col-12 mb-4">
               
               <TextField
                name="email"
                label="Redeemption Status"
                value={giftcardorder.redeemptionStatus}
                fullWidth
                disabled
              />
            </div>
              <div className="col-lg-12 col-12 mb-4">
               
                 <TextField
                  name="email"
                  label="Message sent"
                  value={giftcardorder.message}
                  fullWidth
                  disabled
                />
              </div>
              <div className="col-lg-12 col-12 mb-4">
               
                 <TextField
            
                  label="Sender"
                  value={giftcardorder.sender.firstName+" "+giftcardorder.sender.lastName+' -- '+ giftcardorder.sender.email}
                  fullWidth
                disabled
                />
              </div>
              <div className="col-lg-12 col-12 mb-4">
               
                 <TextField
             name="note"
                  label="Note"
                  value={giftcardorder.note}
                  fullWidth
        
                onChange={handleInputChange}
                />
              </div>
            

            </div>
          </div>




        </DialogContent>

        <DialogActions style={{ padding: 30 }}>
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="secondary">
            Save
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}

export default FormDialogEditGiftcardorder;