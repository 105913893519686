import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from '@material-ui/core';
import RichTextEditor from 'react-rte';
import EditIcon from '@material-ui/icons/Edit';
import { toast } from 'react-toastify';
import Grow from '@material-ui/core/Grow';
import API from "../../../utils/api";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const initialFormState = {
  id: null,
  name: "",
  email: "",
  mobile: "",
  servicename: "",
  rating: 0,
  description: "",
  type: "",
  service: "",
  result: "",
  approved: false,
}

const FormDialogEditFeedback = (props) => {
  const [open, setOpen] = useState(false);
  const [feedback, setFeedback] = useState(initialFormState);
  const [errors, setErrors] = useState({})

  const [selectedLang, setSelectedLang] = useState('en')

  const handleClickOpen = () => {

    setErrors({});
    setFeedback({
      id: props.dataFeedback[0],
      name: props.dataFeedback[1],
      email: props.dataFeedback[2],
      mobile: props.dataFeedback[3],
      servicename: props.dataFeedback[4],
      rating: props.dataFeedback[5],
      description: props.dataFeedback[6],
      type: props.dataFeedback[7],
      service: props.dataFeedback[8],
      result: props.dataFeedback[9],
      approved: props.dataFeedback[10]
    })

    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleInputChange = event => {
    const { name, value, lang } = event.target

    if (lang) {
      setFeedback({
        ...feedback,
        [name]: { ...feedback[name], [selectedLang]: value }
      });

    } else {
      setFeedback({ ...feedback, [name]: value })
    }
  }



  const validate = () => {
    let tempErrors = {};
    let formIsValid = true;

    setErrors(tempErrors);
    return formIsValid;
  }


  const handleSubmit = (e) => {

    const onSuccess = () => {

      setOpen(false);
      toast.success('Data succesfully updated');
    }
    e.preventDefault();

    if (validate()) {

      props.update(feedback.id, feedback, onSuccess)
    }
  }

 

  return (
    <div>
      <IconButton color="primary" onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title" style={{ padding: "30px 30px 0px 30px" }}>Edit feedback</DialogTitle>

        <DialogContent style={{ padding: "30px 30px 10px 30px" }}>
        <TextField
            
            name="description"
            label="description"
            value={feedback.description}
            fullWidth
            onChange={handleInputChange}
            {...(errors.tag && { error: true, helperText: errors.tag })}
       
          />
     
          <br /><br />

          <FormControl
            fullWidth={true}
          >
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              name="approved"
              label="approved"
              value={feedback.approved}
              onChange={handleInputChange}
            >
              <MenuItem value={true}>Approve</MenuItem>
              <MenuItem value={false}>Reject</MenuItem>
            </Select>
          </FormControl>


          <br /><br />

        </DialogContent>

        <DialogActions style={{ padding: 30 }}>
          <Button variant="contained" onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="secondary">
            Save
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}

export default FormDialogEditFeedback;